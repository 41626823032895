import { isAxiosError } from "axios"
import { json, redirect } from "react-router-dom";

import auth from "../../auth/auth";
import { confirmBankAuthentication, fetchCurrentEmployee } from "./dashboardAPI";

const dashboardLoader = async ({request}) => {
  const consentId = (new URL(request.url)).searchParams.get("consent_id");

 if (consentId) {
    try {
      let attempts = 0
      while (attempts < 10) {
        const { data: { status } } = await confirmBankAuthentication(consentId);
        if (status === "received") {
          await new Promise((fulfill) => {
            setTimeout(fulfill, 500)
          })
          attempts += 1
        } else {
          break
        }
      }

      return redirect("/");
    } catch (error) {
      if (isAxiosError(error)) {
        return error;
      } else {
        throw error;
      }
    }
 } else if (auth.isAuthenticated()) {
   const { data } = await fetchCurrentEmployee();
   return json(data);
 } else {
   return json(null);
 }
}

export default dashboardLoader;
