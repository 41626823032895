export function environment() {
  let apiURL = process.env.REACT_APP_API_URL

  if (!apiURL) {
    apiURL = 'http://localhost:3000/api/v1'
  }

  return {
    apiURL: apiURL
  }
}
