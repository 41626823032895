import React, { useState, useEffect } from 'react'
import i18n from '../../../i18n'
import Grid from '@mui/material/Unstable_Grid2'
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Loader from '../../../components/Loader'
import Pagination from '@mui/material/Pagination';
import SettingsIcon from '@mui/icons-material/Settings';
import InvoiceActionsDropdown from './InvoiceActionsDropdown'
import Rows from './Rows'
import { formatMoneyWithCurrency } from '../../../utils/money'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function List(props) {
  const {
    editedInvoiceId,
    invoices,
    fetchInvoices,
    loadingData,
    meta
  } = props
  const t = i18n.getFixedT()
  const [page, setPage] = useState(1)

  const changePage = (_, page) => {
    fetchInvoices(page)
    setPage(page)
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer className="bg-white">
          <Table className="invoices-table">
            <TableHead style={{background: '#0284c7'}}>
              <TableRow>
                <TableCell style={{color: '#FFFFFF', paddingLeft: '20px'}}>{t('customer')}</TableCell>
                <TableCell style={{color: '#FFFFFF' }} align="left">{t('invoiceNumber')}</TableCell>
                <TableCell style={{color: '#FFFFFF' }} align="left">{t('invoiceDate')}</TableCell>
                <TableCell style={{color: '#FFFFFF' }} align="left">{t('dueDate')}</TableCell>
                <TableCell style={{color: '#FFFFFF' }} align="left">{t('amount')}</TableCell>
                <TableCell style={{color: '#FFFFFF' }} align="left">{t('sent')}</TableCell>
                <TableCell align="right"><SettingsIcon sx={{color: '#FFFFFF'}}/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingData ?
                  <TableRow>
                    <TableCell style={{height: '100vh'}}>
                      <Loader
                        style={{
                          position: 'absolute',
                          top: '52%',
                          left: '40%'
                        }}
                        size={100}
                      />
                    </TableCell>
                  </TableRow>
                :
              invoices.map((invoice, i) => (
                <Invoice
                  {...props}
                  invoice={invoice}
                  key={i}
                  open={editedInvoiceId === invoice.id}
                />
              ))}
              {meta.total_pages > 0 &&
                <TableRow>
                  <TableCell style={{width: '100%'}}>
                    <Pagination
                      count={meta.total_pages || 1}
                      onChange={changePage}
                      variant='outlined'
                      shape='rounded'
                    />
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

function Invoice(props) {
  const {
    invoice,
    editInvoice,
    creditInvoice,
    copyInvoice,
    sendInvoice,
    printInvoice,
    cancelInvoice,
    open,
  } = props;
  const [rowsOpen, setRowsOpen] = useState(false);

  useEffect(() => {
    if (open && !rowsOpen) {
      setRowsOpen(true)
    }
  }, [open, rowsOpen, setRowsOpen])

  let color = '#000000'
  if (invoice.paid) {
    color = '#10b97b'
  } else if (invoice.overdue && !invoice.cancelled) {
    color = '#ef4445'
  }

  return (
    <React.Fragment>
      <TableRow style={{background: invoice.cancelled ? '#f1f1f1' : '#fff'}}>
        <TableCell>
          { rowsOpen ?
            <KeyboardArrowUpIcon
              sx={{cursor: 'pointer', marginRight: '10px'}}
              onClick={() => setRowsOpen(false)}
            />
            :
            <KeyboardArrowDownIcon
              sx={{cursor: 'pointer', marginRight: '10px'}}
              onClick={() => setRowsOpen(true)}
            />
          }
          {invoice.customer.name}
        </TableCell>
        <TableCell>
          {invoice.invoice_number}
        </TableCell>
        <TableCell>
          {invoice.invoice_date}
        </TableCell>
        <TableCell style={{color: color}}>
          {invoice.due_date}
        </TableCell>
        <TableCell>
          {formatMoneyWithCurrency(invoice.total, invoice.currency)}
        </TableCell>
        <TableCell>
          {invoice.sent ?
            <CheckIcon className="text-emerald-500"/>
            :
            <CloseIcon className="text-red-500"/>
          }
        </TableCell>
        <TableCell>
          <InvoiceActionsDropdown
            editInvoice={editInvoice}
            copyInvoice={copyInvoice}
            creditInvoice={creditInvoice}
            sendInvoice={sendInvoice}
            invoice={invoice}
            printInvoice={printInvoice}
            cancelInvoice={cancelInvoice}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: '0' }} colSpan={10}>
          <Collapse in={rowsOpen} timeout="auto" unmountOnExit>
            <Rows rows={invoice.invoice_rows}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
