import Decimal from 'decimal.js';

export function vatToAccount(vat) {
  console.log("vat: ", vat)
  console.log("decimal: ", new Decimal(0.25))
  switch (true) {
  case new Decimal(0.25).equals(vat):
      return 3001
  case new Decimal(0.12).equals(vat):
      return 3002
  case new Decimal(0.06).equals(vat):
      return 3003
  case new Decimal(0.0).equals(vat):
      return 3004
  // TODO: Return error instead?
  default:
      return 3001
  }
}
