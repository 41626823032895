import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectActiveFinancialYear } from './activeFinancialYearIdReducer'
import {
  selectFinancialYearsData,
  selectFinancialYearsStatus,
  fetchFinancialYearsAsync,
  updateActiveFinancialYearAsync,
} from './financialYearsSlice'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  presentFinancialYearWithMonth,
} from '../../utils/financialYears'

const FinancialYears = () => {
  const dispatch = useDispatch()
  const financialYearsData = useSelector(selectFinancialYearsData)
  const financialYearsStatus = useSelector(selectFinancialYearsStatus)
  const activeFinancialYear = useSelector(selectActiveFinancialYear)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (financialYearsStatus === 'idle') {
      dispatch(fetchFinancialYearsAsync())
    }
  }, [dispatch, financialYearsStatus])

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectFinancialYear = (financialYear) => {
    setAnchorEl(null)
    dispatch(updateActiveFinancialYearAsync(financialYear.id))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      { financialYearsData.length > 0 &&
        <React.Fragment>
          <Button
            color="inherit"
            onClick={handleOpenMenu}
            aria-controls="companies-menu"
            aria-haspopup="true"
            style={{fontWeight: 'bold', fontSize: 16, marginTop: 2, color: '#ffffff'}}
          >
            {presentFinancialYearWithMonth(activeFinancialYear)}
          </Button>
            <Menu
              id="companies-menu"
              className="companies-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{marginTop: 30, color: '#ffffff'}}
            >
              {
                financialYearsData.map(financialYear =>
                  <MenuItem
                    key={financialYear.id}
                    onClick={() => handleSelectFinancialYear(financialYear)}
                  >
                    {`${financialYear.start_date} - ${financialYear.end_date}`}
                  </MenuItem>
                )
              }
            </Menu>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default FinancialYears
