import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectActiveCompany } from './activeCompanyIdReducer'
import {
  selectCompaniesData,
  updateActiveCompanyAsync,
} from './companiesSlice'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function Companies(props) {
  const dispatch = useDispatch()
  const companiesData = useSelector(selectCompaniesData)
  const activeCompany = useSelector(selectActiveCompany)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenMenu = (event) => {
    if (companiesData.length === 1) {
      window.location.href = '/'
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSelectCompany = (company) => {
    setAnchorEl(null);
    dispatch(updateActiveCompanyAsync(company.id))
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="companies-menu-container">
      { companiesData && companiesData.length > 0 ?
        <React.Fragment>
          <Button
            color="inherit"
            onClick={handleOpenMenu}
            aria-controls="companies-menu"
            aria-haspopup="true"
            style={{fontWeight: 'bold', fontSize: 16, marginTop: 2}}
          >
            {(activeCompany || {}).name || ''}
          </Button>
            <Menu
              id="companies-menu"
              className="companies-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{marginTop: 0}}
            >
              {
                companiesData.map(company =>
                  <MenuItem
                    key={company.id}
                    onClick={() => handleSelectCompany(company)}
                  >
                    {company.name}
                  </MenuItem>
                )
              }
            </Menu>
        </React.Fragment>
        : <Button
            color="inherit"
            style={{fontWeight: 'bold', fontSize: 16, marginTop: 2}}
          >
            {activeCompany || ''}
          </Button>
      }
    </div>
  );
}
