import ClearIcon from '@mui/icons-material/Clear'
import Modal from '@mui/material/Modal'
import { isAxiosError } from 'axios'
import QRCode from 'qrcode'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLoaderData } from 'react-router-dom'

import { selectActiveCompany } from '../../companies/activeCompanyIdReducer'
import { initiateKyc, confirmKyc } from '../dashboardAPI'
import Loader from '../../../components/Loader'

const KycComponent = () => {
  const activeCompany = useSelector(selectActiveCompany)
  const currentEmployee = useLoaderData()
  const [isOpen, setIsOpen] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [readMoreOpen, setReadMoreOpen] = useState(false)

  useEffect(() => {
    if (activeCompany) {
      if (!activeCompany.kyc_valid_until && activeCompany.payment_account_id) {
        setIsOpen(true)
      } else if (Date.parse(activeCompany?.kyc_valid_until) <= Date.now()) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    }
  }, [activeCompany?.id])

  const handleInitiation = useCallback(async () => {
    setIsLoading(true)
    setIsError(false)
    try {
      const { data: { kyc_url: kycUrl } } = await initiateKyc()
      const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}`)
      window.open(kycUrl, '_blank')?.focus()

      while (true) {
        const { data } = await confirmKyc()
        if (data.status === "valid") {
          setIsLoading(false)
          setIsOpen(false)
          setIsError(false)
          break
        } else if (data.status === "invalid") {
          await new Promise((fulfill) => {
            setTimeout(fulfill, 500)
          })
        } else {
          setIsLoading(false)
          setIsError(true)
          break
        }
      }
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      throw error
    }
  }, [])

  return activeCompany && (
    <Modal disableAutoFocus={true} disableEnforceFocus={true} open={isOpen}>
      <div className="flex h-full items-center justify-center p-4 w-full">
        <div className="bg-white max-w-lg overflow-y-auto p-8 relative shadow" style={{maxHeight: "calc(100vh - 2rem)"}}>
          <div className="mb-4">Vi behöver ställa några frågor till dig för att förhindra och motverka penningtvätt och finansiering av terrorism.</div>
          {!isLoading && (
            <>
              <ClearIcon className="absolute cursor-pointer right-2 top-2" onClick={() => setIsOpen(false)} />
              <div className="flex flex-wrap justify-center flex-col">
                <button
                  className="bg-emerald-500 mt-4 text-white py-4 px-4 cursor-pointer"
                  onClick={() => handleInitiation("same_device")}
                >
                  Till frågorna
                </button>
                <button
                  className="bg-sky-500 mt-4 text-white py-4 px-4 cursor-pointer"
                  onClick={() => setReadMoreOpen(!readMoreOpen)}
                >
                  {readMoreOpen ? 'Stäng' : 'Läs mer'}
                </button>
              </div>

              {readMoreOpen &&
                <div className="mt-10">
                  <p>På Ekonomimolnet prioriterar vi din säkerhet och integriteten av våra tjänster. För att säkerställa en trygg och efterlevande miljö genomför vi kundkännedom-procedurer. Här är varför denna process är viktig:</p>
                  <p className="mt-4"><b>1.</b>Säkerhet: Kundkännedom hjälper oss att verifiera din identitet, vilket skyddar dig mot bedrägeri, identitetsstöld och andra ekonomiska brott.</p>
                  <p className="mt-2"><b>2.</b>Efterlevnad: Genom att följa lagliga och regulatoriska krav kan vi upprätthålla våra verksamheter och undvika sanktioner.</p>
                  <p className="mt-2"><b>3.</b>Förtroende: Genom att bekräfta våra kunders identitet skapar vi en säker och pålitlig miljö för alla.</p>
                  <p className="mt-4">Var säker på att din integritet är av yttersta vikt och vi hanterar din information med största omsorg och konfidentialitet. Tack för din förståelse och ditt samarbete.</p>
                </div>
              }

            </>
          )}
          {isLoading && (
            <div className="flex justify-center w-full">
              <Loader size={50} style={{}} />
            </div>
          )}
          {isError && <div className="mt-4">Något gick fel. Prova igen.</div>}
        </div>
      </div>
    </Modal>
  )
}

export default KycComponent
