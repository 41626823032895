import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  approvePayments,
  fetchPayments,
  createPaymentFromAttachment,
  cancelPayment,
} from './paymentsAPI'

export const initialPaymentsState = {
  status: 'idle',
  data: {
    records: [],
    meta: {}
  },
  error: null,
}

export const fetchPaymentsAsync = createAsyncThunk(
  'payments/fetchPayments',
  async (params) => {
    const response = await fetchPayments(params.page, params.statuses)
    return response.data
  }
)

export const approvePaymentsAsync = createAsyncThunk(
  'payments/approvePayments',
  async (params) => {
    const response = await approvePayments(params.paymentIds, params.authMethod)
    return response.data
  }
)

export const createPaymentFromAttachmentAsync = createAsyncThunk(
  'payments/createPaymentFromAttachmentAsync',
  async (formData) => {
    const response = await createPaymentFromAttachment(formData)
    return response.data
  }
)

export const cancelPaymentAsync = createAsyncThunk(
  'payments/cancelPayment',
  async (paymentId) => {
    const response = await cancelPayment(paymentId)
    return response.data
  }
)

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: initialPaymentsState,
  reducers: {
    confirmPayments(state, action) {
      const payments = [...state.data.records]
      payments.map((payment, index) => {
        const updatedPayment = action.payload.find(p => p.id === payment.id)
        if (updatedPayment) {
          payments[index] = updatedPayment
        }
      })
      state.data.records = payments
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPaymentsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchPaymentsAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(approvePaymentsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(approvePaymentsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payments = [...state.data.records]
        payments.map((payment, index) => {
          const updatedPayment = action.payload.payments.find(p => p.id === payment.id)
          if (updatedPayment) {
            payments[index] = updatedPayment
          }
        })
        state.data.records = payments
      })
      .addCase(approvePaymentsAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(createPaymentFromAttachmentAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createPaymentFromAttachmentAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data.records = [
          action.payload,
          ...state.data.records,
        ]
      })
      .addCase(createPaymentFromAttachmentAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(cancelPaymentAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(cancelPaymentAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payments = [...state.data.records]
        const index = payments.findIndex(payment => payment.id === action.payload.id)
        if (index !== -1) {
          payments[index] = action.payload
          state.data.records = payments
        }
      })
      .addCase(cancelPaymentAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})


export const selectPaymentsData = (state) => state.payments.data;
export const selectPaymentsStatus = (state) => state.payments.status;
export const selectPaymentsError = (state) => state.payments.error;

export default paymentsSlice.reducer;
