import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createBrowserRouter, Navigate, Outlet, useLocation, useRouteError, Link } from "react-router-dom";

import auth from "./auth/auth";
import Navbar from "./components/Navbars/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import {
  selectCompaniesStatus,
  selectCompaniesData,
  fetchCompaniesAsync
} from './features/companies/companiesSlice'
import { selectActiveCompany } from './features/companies/activeCompanyIdReducer'
import Dashboard from "./features/dashboard/Dashboard";
import dashboardLoader from "./features/dashboard/dashboardLoader";
import Invoices from "./features/invoices/Invoices";
import Login from "./features/login/Login";
import Register from "./features/register/Register";
import Payments from "./features/payments/Payments";

const AuthenticatedRoute = ({ children }) => {
  const authenticated = auth.isAuthenticated()
  const dispatch = useDispatch()
  const location = useLocation()
  const companiesStatus = useSelector(selectCompaniesStatus)

  useEffect(() => {
    if (authenticated && companiesStatus === 'idle') {
      dispatch(fetchCompaniesAsync())
    }
  }, [authenticated, companiesStatus, dispatch])

  if (!authenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  return children
}

const PaymentEnabledRoute = ({ children }) => {
  const authenticated = auth.isAuthenticated()
  const dispatch = useDispatch()
  const location = useLocation()
  const activeCompany = useSelector(selectActiveCompany)
  const companiesStatus = useSelector(selectCompaniesStatus)
  const companiesData = useSelector(selectCompaniesData)
  const [paymentEnabled, setPaymentEnabled] = useState(true)

  useEffect(() => {
    if (authenticated && companiesStatus === 'idle') {
      dispatch(fetchCompaniesAsync())
    }
  }, [authenticated, companiesStatus, dispatch])

  useEffect(() => {
    companiesData.forEach((company) => {
      if (company.id === activeCompany.id && !company.payment_account_id) {
        setPaymentEnabled(false)
      }
    })
  }, [activeCompany?.id, companiesData])

  if (!authenticated || !paymentEnabled) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return children
}

const UnAuthenticatedRoute = ({ children }) => {
  const authenticated = auth.isAuthenticated()
  const location = useLocation()

  if (authenticated) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return children
}

const Layout = () => {
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = useState(auth.isAuthenticated())
  const [showSidebar, setShowSidebar] = useState(true)

  auth.onAuthenticated = (data) => {
    setAuthenticated(true)
    dispatch({type: 'LOGIN'})
  }

  return (
    <>
      {authenticated && (
        <Sidebar
          showSidebar={showSidebar}
          onToggleSidebar={() => setShowSidebar((value) => !value)}
        />
      )}
      <div className={"relative" + (authenticated && showSidebar ? " md:ml-64" : "")}>
        {authenticated && <Navbar />}
        <Outlet />
      </div>
      {authenticated && <div className="px-4 md:px-10 mx-auto w-full -m-24" />}
    </>
  )
}

function ErrorBoundary() {
  let error = useRouteError();

  if (error?.status === 404) {
    return (
      <div>
        <p>
          Sidan kunde inte hittas.
        </p>
        <p>
          <Link
            to="/"
            sx={{textDecoration: 'underline'}}
          >
            Klicka här för att gå till startsidan
          </Link>
        </p>
      </div>
    )
  }

  return <div><p>Något gick fel, kontakta support på info@ekonomimolnet.se</p></div>;
}

export default createBrowserRouter([
  {
    children: [
      {
        element: (
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        ),
        loader: dashboardLoader,
        index: true
      },
      {
        element: (
          <AuthenticatedRoute>
            <Invoices />
          </AuthenticatedRoute>
        ),
        path: "invoices"
      },
      {
        element: (
          <PaymentEnabledRoute>
            <Payments />
          </PaymentEnabledRoute>
        ),
        path: "payments"
      },
      {
        element: (
          <UnAuthenticatedRoute>
            <Login />
          </UnAuthenticatedRoute>
        ),
        path: "login"
      },
      {
        element: (
          <UnAuthenticatedRoute>
            <Register />
          </UnAuthenticatedRoute>
        ),
        path: "register/:token"
      }
    ],
    element: <Layout />,
    path: "/",
    errorElement: <ErrorBoundary />
  }
])
