import React from 'react';
import auth from '../../auth/auth';
import Button from '@mui/material/Button'
import i18n from '../../i18n';

export default function LogoutButton() {
  const t = i18n.getFixedT();

  const handleClick = () => {
    auth.logout()
    window.location.href = '/login'
  }

  return (
    <Button
      color="inherit"
      onClick={handleClick}
      style={{color: '#ffffff'}}
    >
      {t('logout')}
    </Button>
  )
}
