import { combineReducers } from 'redux'
import activeCompanyIdReducer from '../features/companies/activeCompanyIdReducer'
import activeFinancialYearIdReducer from '../features/financialYears/activeFinancialYearIdReducer'
import dashboardReducer, { intialDashboardState } from '../features/dashboard/dashboardSlice'
import financialYearsReducer, { initialFinancialYearsState } from '../features/financialYears/financialYearsSlice'
import companiesReducer from '../features/companies/companiesSlice'
import invoicesReducer, { initialInvoicesState } from '../features/invoices/invoicesSlice'
import paymentsReducer, { initialPaymentsState } from '../features/payments/paymentsSlice'
import auth from '../auth/auth'

const combinedReducer = combineReducers({
  activeCompanyId: activeCompanyIdReducer,
  activeFinancialYearId: activeFinancialYearIdReducer,
  companies: companiesReducer,
  dashboard: dashboardReducer,
  financialYears: financialYearsReducer,
  invoices: invoicesReducer,
  payments: paymentsReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'financialYears/updateActiveFinancialYear/fulfilled') {
    state = {
      ...state,
      dashboard: intialDashboardState
    }
  }

  if (action.type === 'companies/updateActiveCompany/fulfilled') {
    state = {
      ...state,
      dashboard: intialDashboardState,
      financialYears: initialFinancialYearsState,
      invoices: initialInvoicesState,
      payments: initialPaymentsState,
    }
  }

  if (action.type === 'LOGIN') {
    state = {
      ...state,
      dashboard: intialDashboardState,
      financialYears: {
        ...state.financialYears,
        activeFinancialYearId: auth.getActiveFinancialYear()
      },
    }
  }

  return combinedReducer(state, action)
}
