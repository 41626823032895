import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Link } from "react-router-dom";

export default function Filter(props) {
  const {
    statuses,
    filterOnStatus,
  } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={(statuses.includes('waiting_for_approval') &&  statuses.includes('authorization_pending')) ? "contained" : "outlined" }
              onClick={() => filterOnStatus(['waiting_for_approval', 'authorization_pending']) }
              sx={{fontSize: '12px'}}
            >
              Att hantera
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={(statuses.includes('unprocessed') &&  statuses.includes('supplier_invoice_created')) ? "contained" : "outlined" }
              onClick={() => filterOnStatus(['unprocessed', 'supplier_invoice_created']) }
              sx={{fontSize: '12px'}}
            >
              Under behandling
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={statuses.includes('approved') ? "contained" : "outlined" }
              onClick={() => filterOnStatus(['approved']) }
              sx={{fontSize: '12px'}}
            >
              Signerade
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={statuses.includes('done') ? "contained" : "outlined" }
              onClick={() => filterOnStatus(['done']) }
              sx={{fontSize: '12px'}}
            >
              Betalade
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={(statuses.includes('rejected') && statuses.includes('failed') && statuses.includes('unknown')) ? "contained" : "outlined" }
              onClick={() => filterOnStatus(['rejected', 'failed', 'unknown']) }
              sx={{fontSize: '12px'}}
            >
              Misslyckade
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              variant={statuses.length === 0 ? "contained" : "outlined"}
              onClick={() => filterOnStatus([]) }
              sx={{fontSize: '12px'}}
            >
              Alla
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
