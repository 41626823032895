import React from "react";
import { createPopper } from "@popperjs/core";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TransactionsDropdown = (props) => {
  const {
    transaction,
    attachmentExists,
    onUploadAttachment,
  } = props;

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);

    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const openUploadAttachment = () => {
    onUploadAttachment(transaction)
    setDropdownPopoverShow(false)
  }

  const openAttachment = (attachment) => {
    window.open(attachment.url)
  }

  return (
    <>
    <ClickAwayListener onClickAway={() => setDropdownPopoverShow(false)}>
      <a
        href="#"
        className="py-1 px-3"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <MoreVertIcon
          sx={{
            cursor: 'pointer',
            fontSize: '26px',
            color: transaction.need_attachment ? '#f7bd0c' : (attachmentExists ? '#10B981' : '#64748B'),
          }}
        />
      </a>
    </ClickAwayListener>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => openUploadAttachment()}
          style={{cursor: 'pointer'}}
        >
          Ladda upp underlag
        </a>
        {
          (transaction.attachments || []).map((attachment, i) => (
            <a
              href="#"
              key={i}
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
              }
              onClick={() => openAttachment(attachment)}
              style={{cursor: 'pointer'}}
            >
              Öppna underlag {i +1}
            </a>
          ))
        }
      </div>
    </>
  );
};

export default TransactionsDropdown;
