export const termsOfPaymentOptions = [
    { value: '0', label: '0 dagar' },
    { value: '10', label: '10 dagar' },
    { value: '20', label: '20 dagar' },
    { value: '30', label: '30 dagar' },
    // Autogiro
    // Kontant
    // Postförskott
  ]

export const unitOptions = [
    { label: 'Förpackning', value: 'förp' },
    { label: 'Timmar', value: 'h' },
    { label: 'Kilometer', value: 'km' },
    { label: 'Styck', value: 'st' },
    { label: 'Utlägg', value: 'utl' },
  ];

export const vatOptions = [
    { label: '0%', value: 0 },
    { label: '6%', value: 0.06 },
    { label: '12%', value: 0.12 },
    { label: '25%', value: 0.25 },
  ];

export const discountOptions = [
    { label: '0%', value: 0 },
    { label: '5%', value: 0.05 },
    { label: '10%', value: 0.1 },
    { label: '15%', value: 0.15 },
    { label: '20%', value: 0.20 },
    { label: '25%', value: 0.25 },
    { label: '30%', value: 0.30 },
    { label: '35%', value: 0.35 },
    { label: '40%', value: 0.40 },
    { label: '45%', value: 0.45 },
    { label: '50%', value: 0.50 },
  ];
