import ClearIcon from '@mui/icons-material/Clear'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import { isAxiosError } from 'axios'
import QRCode from 'qrcode'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLoaderData } from 'react-router-dom'
import { selectActiveCompany } from '../../companies/activeCompanyIdReducer'
import { approvePayments, confirmPayments } from '../paymentsAPI'
import Loader from '../../../components/Loader'
import { formatMoneyWithCurrency } from '../../../utils/money'
import { LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import StyledDatePicker from '../../../components/StyledDatePicker'
import Holidays from 'date-holidays'

const BankAuthenticationComponent = (props) => {
  const { open, paymentsToApprove, onSuccess, onError, onClose } = props
  const activeCompany = useSelector(selectActiveCompany)
  const currentEmployee = useLoaderData()
  const [isOpen, setIsOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [qr, setQr] = useState(null)
  const [payDates, setPayDates] = useState([])

  const minPayDate = new Date()
  minPayDate.setDate(minPayDate.getDate() + 1)

  const hd = new Holidays('SE');
  const disableDate = (date) => {
    const d = new Date(date)
    return !!hd.isHoliday(new Date(date)) || d.getDay() === 6 || d.getDay() === 0
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    setPayDates(paymentsToApprove.map((payment) => {
      return {
        id: payment.id,
        due_date: payment.due_date
      }
    }))
  }, [paymentsToApprove])

  const handleError = () => {
    setIsOpen(false)
    onError()
  }

  const addPaymentDate = (paymentId, date) => {
    const tmpPayDates = [...payDates]
    tmpPayDates.map((payment, index) => {
      if (payment.id === paymentId) {
        tmpPayDates[index] = {
          id: paymentId,
          due_date: date.format('YYYY-MM-DD')
        }
      }
    })
    setPayDates(tmpPayDates)
  }

  const handleInitiation = useCallback(async (method, paymentIds, payDates) => {
    setIsLoading(true)
    setIsError(false)

    try {
      const {
        data: {
          basket_id: basketId,
          authorization_id: authorizationId
        }
      } = await approvePayments(paymentIds, payDates, method)

      while (true) {
        const { data } = await confirmPayments(basketId, authorizationId, paymentIds)
        if (data?.status === "started" || data?.status === "authenticationStarted") {
          if (method === "same_device") {
            const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}/?basket_id=${basketId}`)
            window.location.href = `https://app.bankid.com/?autostarttoken=${data.challenge.data}&redirect=${url}`
            break
          } else if (method === "other_device") {
            setIsLoading(false)
            setQr(data.challenge.type === "data" ? await QRCode.toDataURL(data.challenge.data) : data.challenge.data)
            await new Promise((fulfill) => {
              setTimeout(fulfill, 500)
            })
          }
        } else {
          if (data?.status === "finalised") {
            setIsOpen(false)
            onSuccess(data.payments)
          } else if (data?.status === "received") {
            await new Promise((fulfill) => {
              setTimeout(fulfill, 500)
            })
          } else {
            setIsError(true)
            setIsLoading(false)
            setQr(null)
          }
          break
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        setIsLoading(false)
        setIsError(true)
        setQr(null)
      } else {
        throw error
      }
    }
  }, [])

  return activeCompany && (
    <Modal open={isOpen}>
      <div className="flex h-full items-center justify-center p-4 w-full">
        <div className="bg-white max-w-lg overflow-y-auto p-8 relative shadow" style={{maxHeight: "calc(100vh - 2rem)"}}>
          <div className="mb-4">
            <h1 style={{fontSize: 20, fontWeight: 'bold', margin: '0 0 10px 0'}}>Godkänn betalningar</h1>
            {paymentsToApprove?.map((payment, i) => (
              <div key={i}>
                <p><b>{payment.supplier_name}</b></p>
                {payment.invoice_number &&
                  <p>Fakturanummer: {payment.invoice_number}</p>
                }
                <p>Summa: {formatMoneyWithCurrency(payment.amount, 'SEK')}</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledDatePicker
                    onChange={(value) => addPaymentDate(payment.id, value)}
                    value={dayjs(payment.due_date)}
                    format="YYYY-MM-DD"
                    slotProps={{ textField: { size: 'small' } }}
                    fontSize='13px'
                    height='36px'
                    minDate={dayjs(minPayDate)}
                    disableDateFunc={disableDate}
                  />
                </LocalizationProvider>
                <Divider sx={{margin: '10px 0 5px 0'}}/>
              </div>
            ))}
          </div>
          {!isLoading && !qr && !isError && (
            <>
              <ClearIcon className="absolute cursor-pointer right-2 top-2" onClick={onClose} />
              <div className="flex flex-wrap justify-center">
                <button
                  className="bg-emerald-500 text-white py-4 px-4 cursor-pointer"
                  onClick={() => handleInitiation("same_device", paymentsToApprove.map(p => p.id), payDates)}
                >
                  Öppna BankID på denna enhet
                </button>
                <button
                  className="bg-emerald-500 mt-4 text-white py-4 px-4 cursor-pointer"
                  onClick={() => handleInitiation("other_device", paymentsToApprove.map(p => p.id), payDates)}
                >
                  Använd BankID på en annan enhet
                </button>
              </div>
            </>
          )}
          {isLoading && (
            <div className="flex justify-center w-full">
              <Loader size={50} style={{}} />
            </div>
          )}
          {qr && (
            <>
              <div className="flex flex-col items-center w-full">
                <img alt="QR" className="h-48 w-48" src={qr} />
                <div className="mt-2 text-center text-sm">Öppna BankID och scanna QR-koden.</div>
              </div>
            </>
          )}
          {isError &&
            <>
              <div className="mt-4">Något gick fel. Prova igen.</div>
              <button
                className="bg-emerald-500 mt-4 text-white py-4 px-4 cursor-pointer"
                onClick={handleError}
              >
                Stäng
              </button>
            </>
          }
        </div>
      </div>
    </Modal>
  )
}

export default BankAuthenticationComponent
