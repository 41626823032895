import request from '../../services/request'

export const fetchActiveCompany = () => {
  return request('get', 'employees/active_company')
}

export const fetchCustomers = () => {
  return request('get', 'customers')
}

export const searchCustomers = (name) => {
  return request('get', `customers?query=${name}`)
}

export const searchAccounts = (query) => {
  return request('get', `accounts?query=${query}`)
}

export const fetchArticles = () => {
  return request('get', 'articles')
}

export const fetchTermsOfPayment = () => {
  return request('get', 'terms_of_payment')
}

export const fetchUnits = () => {
  return request('get', 'units')
}

export const createInvoice = (params) => {
  return request('post', 'invoices', params)
}

export const updateInvoice = (params) => {
  return request('put', `invoices/${params.id}`, params)
}

export const removeInvoice = (invoiceId) => {
  return request('delete', `invoices/${invoiceId}`)
}

export const fetchInvoices = (page) => {
  return request('get', `invoices?page=${page}`)
}

export const getInvoice = (invoiceId) => {
  return request('get', `invoices/${invoiceId}`)
}

export const sendInvoice = (invoiceId) => {
  return request('post', `invoices/${invoiceId}/send_email`)
}

export const createArticle = (params) => {
  return request('post', 'articles', params)
}

export const createCustomer = (params) => {
  return request('post', 'customers', params)
}

export const updateCustomer = (params) => {
  return request('put', `customers/${params.id}`, params)
}
