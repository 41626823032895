import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Navigate } from "react-router-dom"
import i18n from '../../i18n'
import auth from '../../auth/auth'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.ekonomimolnet.se/" target="_blank">
        EkonomiMolnet Sverige AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function Login() {
  const t = i18n.getFixedT()
  const [authenticated, setAuthenticated] = React.useState(auth.isAuthenticated())
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [rememberEmail, setRememberEmail] = React.useState(auth.getRememberedEmail())
  const [loginError, setLoginError] = React.useState(false)

  if (auth.getRememberedEmail() && !email) {
    setEmail(auth.getEmail())
  }

  const handleSubmit = async(e) => {
    e.preventDefault()

    const authResponse = await auth.authenticate(email, password, rememberEmail)

    if (!authResponse) {
      setLoginError(true)
    } else {
      setLoginError(false)
      setAuthenticated(true)
    }
  }

  if (authenticated){
    return (
      <Navigate to="/" replace />
    )
  }

  return (
    <Grid container className="bg-sky-600" style={{height: '100vh', width: '100%'}}>
      <Container component="main" maxWidth="xs">
        <h2 style={{color: '#ffffff', fontSize: '40px', marginTop: '15px'}}>EkonomiMolnet</h2>
        <CssBaseline />
        <Box
          className="bg-white"
          sx={{
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px'
          }}
        >
          <Avatar sx={{ m: 1}} style={{background: 'rgba(239, 68, 68, 1)'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              value={email}
              margin="normal"
              className="text-white"
              required
              fullWidth
              id="email"
              label={t('emailAdress')}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              value={password}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {loginError &&
              <p style={{color: 'rgba(239, 68, 68, 1)'}}>Fel e-postadress eller lösenord</p>
            }
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" checked={rememberEmail} />}
              onChange={() => setRememberEmail(!rememberEmail)}
              label={t('rememberMe')}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{background: 'rgba(16, 185, 129, 1)'}}
              sx={{ mt: 3, mb: 2 }}
            >
              {t('login')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {t('forgotPassword')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} style={{color: '#ffffff'}}/>
      </Container>
      </Grid>
  )
}
