/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { useHref } from "react-router-dom";
import { Link } from "react-router-dom";
import i18n from '../../i18n'
import Grid from '@mui/material/Unstable_Grid2'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {
  selectCompaniesData,
} from '../../features/companies/companiesSlice'
import { selectActiveCompany } from '../../features/companies/activeCompanyIdReducer'

export default function Sidebar(props) {
  const t = i18n.getFixedT()
  const href = useHref();
  const [paymentEnabled, setPaymentEnabled] = useState(true)
  const activeCompany = useSelector(selectActiveCompany)
  const companiesData = useSelector(selectCompaniesData)

  useEffect(() => {
    companiesData.forEach((company) => {
      if (company.id === activeCompany.id) {
        setPaymentEnabled(!!company.payment_account_id)
      }
    })
  }, [activeCompany?.id, companiesData])

  return (
      <>
        {props.showSidebar ?
          <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center text-left justify-between relative md:w-64 z-10 py-4 px-6">
                     {/* Toggler */}
            <Grid container spacing={2}>
              <Grid item xs={9} style={{marginTop: '10px'}}>
                  <Link
                  className="mt-8 text-slate-600 text-sm uppercase font-bold"
                  to="/"
                >
                  EkonomiMolnet
                </Link>
                </Grid>
              <Grid item xs={3}>
                <button
                  type="button"
                  className="text-slate-600"
                  style={{width: '50px', height: '50px'}}
                  onClick={() => props.onToggleSidebar()}
                >
                  <i className="fas fa-bars" style={{fontSize: '30px'}}></i>
                </button>
                </Grid>
              </Grid>
            <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
              <div
                className={
                  "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
                }
              >
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (href === "/invoices"
                          ? "text-sky-500 hover:text-sky-600"
                          : "text-slate-700 hover:text-slate-500")
                      }
                      to="/invoices"
                    >
                      <i
                        className={
                          "fas fa-tv mr-2 text-sm " +
                          (href === "/invoices"
                            ? "opacity-75"
                            : "text-slate-300")
                        }
                      ></i>{" "}
                      {t('invoices')}
                    </Link>
                  </li>
                  {paymentEnabled &&
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (href === "/payments"
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                        }
                        to="/payments"
                      >
                        <i
                          className={
                            "fas fa-credit-card mr-2 text-sm " +
                            (href === "/payments"
                              ? "opacity-75"
                              : "text-slate-300")
                          }
                        ></i>{" "}
                        {t('payments')}
                      </Link>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </nav>
        :
          <button
            className="cursor-pointer text-white"
            type="button"
            style={{
              position: 'absolute',
              top: '12px',
              left: '5px',
              width: '50px',
              height: '50px',
              zIndex: 999
            }}
            onClick={() => props.onToggleSidebar()}
          >
            <i className="fas fa-bars" style={{fontSize: '30px'}}></i>
          </button>
      }
    </>
  );
}
