import {environment} from "../../config/environment";
import axios from 'axios';

export function authenticate(email, password) {
  return axios.post(`${environment().apiURL}/tokens`, {
    email: email,
    password: password
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error()
    }
    return response.data
  })
}
