import React from "react";
import { formatMoneyWithCurrency } from '../../utils/money'
import CardStats from "../Cards/CardStats.js";

export default function HeaderStats(props) {
  const { data } = props;
  const revenue = data.revenue || {};
  const debt = data.debt || {};
  const receivables = data.receivables || {};
  const balance = data.balance || {};

  let increase;
  if (revenue.increase_percentage) {
    increase = revenue.increase_percentage.toString()
  }

  let decrease;
  if (revenue.decrease_percentage) {
    decrease = revenue.decrease_percentage.toString()
  }

  return (
    <>
      {/* Header */}
      <div className="relative bg-sky-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap text-left">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                {
                  revenue.amount ? (
                    <CardStats
                      statSubtitle="Omsättning"
                      statTitle={formatMoneyWithCurrency(revenue.amount, revenue.currency, true)}
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-emerald-500"
                      statDescription="Nettoomsättning"
                      statIconName="far fa-chart-bar"
                      statIconColor="bg-emerald-500"
                    />
                  ) : (
                    <CardStats
                      statSubtitle="Omsättning"
                      statTitle="Laddar..."
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-emerald-500"
                      statDescription="Nettoomsättning"
                      statIconColor="bg-emerald-500"
                    />
                  )
                }
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                {
                  debt.amount !== undefined ? (
                    <CardStats
                      statSubtitle="Leverantörsskulder"
                      statTitle={formatMoneyWithCurrency(debt.amount, debt.currency, true)}
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Väntar på utbetalning"
                      statIconName="fas fa-credit-card"
                      statIconColor="bg-red-500"
                    />
                  ) : (
                    <CardStats
                      statSubtitle="Leverantörsskulder"
                      statTitle="Laddar.."
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Väntar på utbetalning"
                      statIconName="fas fa-credit-card"
                      statIconColor="bg-red-500"
                    />
                  )
                }
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                {
                  receivables.amount ? (
                    <CardStats
                      statSubtitle="Kundfodringar"
                      statTitle={formatMoneyWithCurrency(receivables.amount, receivables.currency, true)}
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Väntar på inbetalning"
                      statIconName="fas fa-file-invoice"
                      statIconColor="bg-orange-500"
                    />
                  ) : (
                    <CardStats
                      statSubtitle="Kundfodringar"
                      statTitle="Laddar.."
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Väntar på inbetalning"
                      statIconName="fas fa-file-invoice"
                      statIconColor="bg-orange-500"
                    />
                  )
                }
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                {
                  balance.amount ? (
                    <CardStats
                      statSubtitle="Balans"
                      statTitle={formatMoneyWithCurrency(balance.amount, balance.currency, true)}
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Saldo på bankkontot"
                      statIconName="fas fa-wallet"
                      statIconColor="bg-sky-500"
                    />
                  ) : (
                    <CardStats
                      statSubtitle="Balans"
                      statTitle="Laddar.."
                      statArrow=""
                      statPercent=""
                      statPercentColor="text-red-500"
                      statDescription="Saldo på bankkontot"
                      statIconName="fas fa-wallet"
                      statIconColor="bg-sky-500"
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
