import React, { useState } from "react";
import TransactionsDropdown from "../Dropdowns/TransactionsDropdown.js";
import { formatMoneyWithCurrency } from '../../utils/money'
import fileUpload from '../../services/fileUpload'
import FileUploader from '../FileUploader'
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';

export default function CardLatestTransactions(props) {
  const {
    transactions = {},
    transactionType,
    transactionPage,
    fetchTransactions,
  } = props;
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [activeTransaction, setActiveTransaction] = useState(null)
  const [successfulUpload, setSuccessfulUpload] = useState(false)

  const onUploadAttachment = (transaction) => {
    setActiveTransaction(transaction)
    setUploadModalOpen(true)
  }

  const uploadAttachments = async(attachments, fileName) => {
    if (transactionType.value === 'tax_account') {
      setUploadModalOpen(false)
      return
    }

    const formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('files[]', attachment.file);
    });

    formData.append('voucher_id', activeTransaction.id)
    formData.append('date', activeTransaction.date)
    formData.append('description', activeTransaction.description)

    const response = await fileUpload('vouchers/upload_attachment', formData)

    if (response.status === 200) {
      setSuccessfulUpload(true)
      setUploadModalOpen(false)
      fetchTransactions(transactionType, transactionPage)
    } else {
      console.log("error")
      // present error
    }
  }

  const selectPage = (_, page) => {
    fetchTransactions(transactionType, page)
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr className="bg-slate-50">
                <th className="px-6 bg-slate-50 text-slate-500 align-middle border border-solid border-slate-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Beskrivning
                </th>
                <th className="px-6 bg-slate-50 text-slate-500 align-middle border border-solid border-slate-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Datum
                </th>
                <th className="px-6 bg-slate-50 text-slate-500 align-middle border border-solid border-slate-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                  Belopp
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100 text-right"
                  }
                >
                </th>
              </tr>
            </thead>
            <tbody>
              {(transactions.data || []).map((trans, i) =>
                <tr key={i} style={{background: (i%2 === 0 ? '#fff' : '#f1f1f1')}}>
                  <th
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm p-2 text-left"
                    style={{
                      'maxWidth': '200px',
                      'overflow': 'hidden',
                      'whiteSpace': 'nowrap',
                      'textOverflow': 'ellipsis',
                    }}
                  >

                    {trans.description}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-left">
                    {trans.date}
                  </td>
                  <td className={"border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-right " + (trans.amount > 0 ? "text-emerald-500" : "text-red-500")}>
                    {formatMoneyWithCurrency(trans.amount, trans.currency, true)}
                  </td>
                  {transactionType.value !== 1630 ?
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-right">
                      <TransactionsDropdown
                        transaction={trans}
                        attachmentExists={
                          (trans.attachments || []).length > 0 ||
                          (successfulUpload && activeTransaction?.id === trans.id)
                        }
                        onUploadAttachment={onUploadAttachment}
                      />
                    </td>
                    : <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-right"></td>
                  }
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Grid container spacing={2} style={{margin: '2px 0'}}>
          <Grid item xs={8} style={{margin: '0 auto'}}>
            <Pagination
              count={transactions.meta.total_pages || 1}
              onChange={selectPage}
              variant='outlined'
              shape='rounded'
              page={transactionPage}
            />
          </Grid>
        </Grid>
      </div>
      <FileUploader
        title="Ladda upp underlag"
        onClose={() => setUploadModalOpen(false)}
        onUpload={uploadAttachments}
        open={uploadModalOpen}
        multiple={true}
      />
    </>
  );
}
