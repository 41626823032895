export function presentFinancialYearWithMonth(financialYear) {
  if (!financialYear) return '';

  const from = financialYear.start_date.split('-');
  const to = financialYear.end_date.split('-');

  return `${from[0]}-${from[1]} - ${to[0]}-${to[1]}`;
}

export function presentFinancialYear(financialYear) {
  if (!financialYear) return '';

  const from = financialYear.start_date.split('-');
  const to = financialYear.end_date.split('-');

  if (from[0] === to[0]) {
    return from[0];
  }

  return `${from[0]}/${to[0]}`;
}

export function presentPreviousFinancialYear(financialYears, selectedYear) {
  if (!selectedYear || !financialYears) {
    return '';
  }

  const selectedFrom = parseInt(selectedYear.start_date.split('-')[0]);

  const previousYear = financialYears.find((year) => {
    const from = year.start_date.split('-');

    return selectedFrom - parseInt(from[0]) === 1;
  });

  return presentFinancialYear(previousYear);
}
