import request from '../../services/request'
import fileUpload from '../../services/fileUpload'

export const fetchPayments = (page, statuses) => {
  return request('get', `payments?page=${page}&statuses=${statuses}`)
}

export const approvePayments = (paymentIds, payDates, authMethod) => {
  return request('post', `payments/initiate_giro_payments`, {
    payment_ids: paymentIds,
    pay_dates: payDates,
    method: authMethod,
  })
}

export const confirmPayments = (basketId, authorizationId, paymentIds) => {
  return request('post', `payments/confirm_giro_payments`, {
    basket_id: basketId,
    authorization_id: authorizationId,
    payment_ids: paymentIds,
  })
}

export const createPaymentFromAttachment = async (formData) => {
  return new Promise((resolve, reject) => {
    fileUpload('payments/create_from_attachment', formData)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve({ data: data })
          }).catch(err => reject(err))
        } else {
          reject({ error: 'Error creating payment', http_status: response.status })
        }
      })
      .catch(error => reject(error));
  });
}

export const cancelPayment = async (paymentId) => {
  return request('put', `payments/cancel/${paymentId}`)
}
