import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { formatMoneyWithCurrency } from '../../../utils/money'

export default function Rows(props) {
  const { rows } = props

  return (
     <TableContainer>
      <Table size="small" aria-label="collapsible table" className="invoices-table">
        <TableHead style={{background: '#0284c7'}}>
          <TableRow>
            <TableCell style={{color: '#FFFFFF' }}>Beskrivning</TableCell>
            <TableCell style={{color: '#FFFFFF' }}>Enhet</TableCell>
            <TableCell style={{color: '#FFFFFF' }}>Antal</TableCell>
            <TableCell style={{color: '#FFFFFF' }}>À-pris</TableCell>
            <TableCell style={{color: '#FFFFFF' }}>Rabatt</TableCell>
            <TableCell style={{color: '#FFFFFF' }}>Summa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.unit.description}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{formatMoneyWithCurrency(row.amount, 'SEK')}</TableCell>
              <TableCell>{`${row.discount * 100}%`}</TableCell>
              <TableCell>{formatMoneyWithCurrency(row.total, 'SEK')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
