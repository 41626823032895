import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import {
  fetchActiveCompanyAsync,
  selectActiveCompanyData,
  selectActiveCompanyStatus,
  selectCustomersData,
  selectCustomersStatus,
  searchCustomersAsync,
  selectAccountsData,
  searchAccountsAsync,
  selectArticlesData,
  selectArticlesStatus,
  fetchArticlesAsync,
  selectUnitsData,
  selectUnitsStatus,
  selectInvoicesData,
  selectInvoicesStatus,
  fetchUnitsAsync,
  createInvoiceAsync,
  updateInvoiceAsync,
  fetchInvoicesAsync,
  sendInvoiceAsync,
  createArticleAsync,
  createCustomerAsync,
  updateCustomerAsync,
} from './invoicesSlice'
import Form from './components/Form';
import List from './components/List';

export default function Invoices(props) {
  const { activeCompanyName } = props;
  const dispatch = useDispatch()

  const activeCompanyData = useSelector(selectActiveCompanyData)
  const activeCompanyStatus = useSelector(selectActiveCompanyStatus)
  const customersStatus = useSelector(selectCustomersStatus)
  const customersData = useSelector(selectCustomersData)
  const accountsData = useSelector(selectAccountsData)
  const articlesData = useSelector(selectArticlesData)
  const articlesStatus = useSelector(selectArticlesStatus)
  const unitsData = useSelector(selectUnitsData)
  const unitsStatus = useSelector(selectUnitsStatus)
  const invoicesData = useSelector(selectInvoicesData)
  const invoicesStatus = useSelector(selectInvoicesStatus)
  const [formOpen, setFormOpen] = useState(false)
  const [customerFormOpen, setCustomerFormOpen] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState(null)
  const [fromInvoiceCopy, setFromInvoiceCopy] = useState(false)
  const [creditedInvoice, setCreditedInvoice] = useState(false)
  const [editedInvoiceId, setEditedInvoiceId] = useState(null)

  useEffect(() => {
    if (activeCompanyStatus === 'idle') {
      dispatch(fetchActiveCompanyAsync())
    }
  }, [activeCompanyStatus, dispatch])

  useEffect(() => {
    if (articlesStatus === 'idle') {
      dispatch(fetchArticlesAsync())
    }
  }, [articlesStatus, dispatch])

  useEffect(() => {
    if (unitsStatus === 'idle') {
      dispatch(fetchUnitsAsync())
    }
  }, [unitsStatus, dispatch])

  useEffect(() => {
    if (invoicesStatus === 'idle') {
      dispatch(fetchInvoicesAsync(1))
    }
  }, [invoicesStatus, dispatch])

  useEffect(() => {
    if (customersStatus === 'idle') {
      dispatch(searchCustomersAsync(''))
    }
  }, [customersStatus, dispatch])

  const createArticle = async(params) => {
    const response = await dispatch(createArticleAsync(params))
    return response.payload
  }

  const createCustomer = async(params) => {
    const response = await dispatch(createCustomerAsync(params))
    setCustomerFormOpen(false)
    return response.payload
  }

  const updateCustomer = async(params) => {
    const response = await dispatch(updateCustomerAsync(params))
    setCustomerFormOpen(false)
    return response.payload
  }

  const searchCustomers = async(name) => {
    // TODO: Do we need debounce?
    dispatch(searchCustomersAsync(name))
  }

  const searchAccounts = async(query) => {
    // TODO: Do we need debounce?
    dispatch(searchAccountsAsync(query))
  }

  const createInvoice = async(params) => {
    const response = await dispatch(createInvoiceAsync(params))
    return response
  }

  const updateInvoice = async(params) => {
    await dispatch(updateInvoiceAsync(params))
    setEditedInvoiceId(params.id)
  }

  const editInvoice = (invoice) => {
    setActiveInvoice(invoice)
    setFormOpen(true)
  }

  const copyInvoice = (invoice) => {
    setActiveInvoice(invoice)
    setFromInvoiceCopy(true)
    setFormOpen(true)
  }

  const creditInvoice = (invoice) => {
    const rows = [...invoice.invoice_rows]
    const creditRows = rows.map((row) => {
      const rowCopy = {...row}
      rowCopy.amount = row.amount * -1
      return rowCopy
    })
    const credited = {...invoice}
    credited.invoice_rows = creditRows
    setActiveInvoice(credited)
    setFormOpen(true)
    setCreditedInvoice(true)
  }

  const fetchInvoices = (page) => {
    dispatch(fetchInvoicesAsync(page))
  }

  const sendInvoice = (invoiceId) => {
    dispatch(sendInvoiceAsync(invoiceId))
  }

  const printInvoice = (invoice) => {
    window.open(invoice.pdf_url)
    if (!invoice.booked) {
      dispatch(updateInvoiceAsync({id: invoice.id, booked: true}))
    }
  }

  const cancelInvoice = (invoice) => {
    dispatch(updateInvoiceAsync({id: invoice.id, cancelled: true}))
  }

  const toggleForm = () => {
    setActiveInvoice(null)
    setFromInvoiceCopy(false)
    setFormOpen(!formOpen)
  }

  const toggleCustomerForm = () => {
    setCustomerFormOpen(!customerFormOpen)
  }

  return (
    <div className="relative md:pt-32 pb-32 pt-12">
      <Grid container justify="center">
        <Grid item xs={11} xsOffset={0.5} sx={{marginBottom: '20px'}}>
          <button
            onClick={toggleForm}
            className="bg-emerald-500 text-white py-4 px-4 cursor-pointer"
          >
            {formOpen ? "Stäng faktura" : "Skapa faktura"}
          </button>
        </Grid>
        <Grid item xs={11} xsOffset={0.5}>
          {formOpen &&
            <Form
              activeCompany={activeCompanyData}
              accounts={accountsData}
              articles={articlesData}
              createArticle={createArticle}
              createInvoice={createInvoice}
              creditedInvoice={creditedInvoice}
              customers={customersData}
              createCustomer={createCustomer}
              updateCustomer={updateCustomer}
              fromCopy={fromInvoiceCopy}
              invoice={activeInvoice}
              searchAccounts={searchAccounts}
              searchCustomers={searchCustomers}
              units={unitsData}
              updateInvoice={updateInvoice}
              customerFormOpen={customerFormOpen}
              toggleCustomerForm={toggleCustomerForm}
              printInvoice={printInvoice}
            />
          }
        </Grid>
        <Grid item xs={11} xsOffset={0.5} sx={{marginTop: '8px'}}>
          <List
            copyInvoice={copyInvoice}
            editInvoice={editInvoice}
            creditInvoice={creditInvoice}
            sendInvoice={sendInvoice}
            printInvoice={printInvoice}
            invoices={invoicesData.records}
            loadingData={invoicesStatus === 'loading'}
            meta={invoicesData.meta}
            editedInvoiceId={editedInvoiceId}
            cancelInvoice={cancelInvoice}
            fetchInvoices={fetchInvoices}
          />
        </Grid>
      </Grid>
    </div>
  );
}
