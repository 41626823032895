import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function HighestExpenditureChartComponent(props) {
  const { expenditures = [] } = props;

  const data = {
    labels: expenditures.map(item => item.description),
    datasets: [
      {
        currency: 'SEK',
        data: expenditures.map(item => item.amount),
        backgroundColor: [
          'rgba(239, 68, 68, 1)',
          'rgba(14, 165, 233, 1)',
          'rgba(249, 115, 22, 1)',
          'rgba(16, 185, 129, 1)',
          'rgba(168, 85, 247, 1)',
        ],
        borderColor: [
          'rgba(239, 68, 68, 1)',
          'rgba(14, 165, 233, 1)',
          'rgba(249, 115, 22, 1)',
          'rgba(16, 185, 129, 1)',
          'rgba(168, 85, 247, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    aspectRatio: 1.5,
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
        labels: {
          color: '#ffffff'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue} SEK`
          }
        }
      }
    }
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-slate-700">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center text-left">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-slate-400 mb-1 text-xs font-semibold">
              Överblick
            </h6>
            <h2 className="text-white text-xl font-semibold mb-2">Högsta utgifter</h2>
            <hr/>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto px-4 pb-4">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  )
}
