import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function StyledDatePicker(props) {
  const {
    slotProps,
    onChange,
    value,
    format,
    fontSize,
    height,
    error,
    minDate,
    disableDateFunc,
  } = props;

  return (
    <DatePicker
      sx={{
        background: '#fff',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
          border: error ? '1px solid red' : 'none',
          height: height || '36px',
          fontSize: fontSize || '14px',
          '&:hover fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          },
          '&.Mui-active fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          }
        },
        '& .MuiIconButton-root': {
          '&:focus': {
            outline: 'none!important',
          }
        }
      }}
      onChange={onChange}
      value={value}
      format={format}
      slotProps={slotProps}
      minDate={minDate || null}
      shouldDisableDate={disableDateFunc ? disableDateFunc : () => false}
    />
  )
}
