import auth from '../../auth/auth'

const activeCompanyIdReducer = (state = null, action) => {
  switch (action.type) {
    case 'companies/fetchCompanies/fulfilled':
      return auth.getActiveCompany();
    case 'companies/updateActiveCompany/fulfilled':
      return action.payload;
    default:
      return state;
  }
}

export const selectActiveCompany = (state) => {
  return state.companies.data.find((company) => {
    return company.id === state.activeCompanyId;
  });
};

export default activeCompanyIdReducer;
