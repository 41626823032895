import React from 'react'
import CardBarChart from '../../../components/Cards/CardBarChart'
import { MONTHS } from '../../../utils/months'

const ExpenditureChartComponent = (props) => {
  const { data } = props
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: "#fff",
        },
        align: "end",
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue} ${context.dataset.currency}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }
  }

  const labels = Object.entries(MONTHS).map(([k, v]) => { return v })
  // let currency = 'SEK'
  // let currentYear = ''
  // if (data.income && data.income.current_year[0].currency) {
  //   currency = data.current_year[0].currency
  //   currentYear = data.current_year[0].date.split('-')[0]
  // }
  const income = (data.income.current_year || []).map((item) => {
    return item.amount
  })
  const expenditure = (data.expenditure.current_year || []).map((item) => {
    return item.amount
  })

  const chartData = {
    labels: labels,
    datasets: [
      {
        currency: 'SEK',
        label: 'Intäkter',
        data: income,
        backgroundColor: "#10b981",
        borderColor: "#10b981",
        fill: false,
        barThickness: 8,
      },
      {
        currency: 'SEK',
        label: 'Utgifter',
        data: expenditure,
        backgroundColor: "#fff",
        borderColor: "#fff",
        fill: false,
        barThickness: 8,
      },
    ],
  }

  return (
    <CardBarChart title="Överblick" subTitle="Intäkter/Utgifter" options={chartOptions} data={chartData} />
  )
}

export default ExpenditureChartComponent

