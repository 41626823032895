import request from '../../services/request'

export const confirmBankAuthentication = (consentId) => {
  return request('post', `companies/confirm_bank_authentication`, {consent_id: consentId})
}

export const fetchBankAccounts = () => {
  return request('get', 'accounts/bank_and_tax')
}

export const fetchCurrentEmployee = () => {
  return request('get', 'employees/current')
}

export const fetchEconomyData = (accumulate, includePreviousYear) => {
  const params = `accumulate=${accumulate}&include_previous_year=${includePreviousYear}`
  const url = `charts/economy?${params}`
  return request('get', url)
}

export const fetchTransactions = (accountNumber, page, filterOnMissingAttachment) => {
  let url = `vouchers?account_number=${accountNumber}&page=${page}`

  if (filterOnMissingAttachment) {
    url += '&missing_attachment=true'
  }

  return request('get', url)
}

export const fetchKeyFiguresSummary = () => {
  return request('get', 'key_figures/summary')
}

export const initiateBankAuthentication = (method) => {
  return request('post', 'companies/initiate_bank_authentication', {method})
}

export const initiateKyc = () => {
  return request('post', 'companies/initiate_kyc', {})
}

export const confirmKyc = () => {
  return request('post', 'companies/confirm_kyc', {})
}
