export function translateStatus(status) {
  switch (status) {
    case "unprocessed":
    case "supplier_invoice_created":
      return "Under behandling"
    case "waiting_for_approval":
      return "Väntar på signering"
    case "authorization_pending":
      // TODO: För dessa bör vi ha ett alternativt där vi kan skapa en signing basket med
      // redan skapade id:n (payment.external_id)
      return "Väntar på signering"
    case "approved":
      return "Signerad"
    case "rejected":
    case "failed":
      return "Misslyckades, kontakta kundtjänst"
    case "cancelled":
      return "Avbruten"
    case "unknown":
      return "Okänd, kontakt kundtjänst"
    case "done":
      return "Betalad"
    default:
      return "Under behandling"
  }
}
