import React from 'react'
import CardLineChart from '../../../components/Cards/CardLineChart'
import { MONTHS } from '../../../utils/months'

const ResultChartComponent = (props) => {
  const { data } = props
  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue} ${context.dataset.currency}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }
  }

  const labels = Object.entries(MONTHS).map(([k, v]) => { return v })
  let currency = 'SEK'
  let currentYear = ''

  if (data.current_year?.[0]?.currency) {
    currency = data.current_year[0].currency
    currentYear = data.current_year[0].date.split('-')[0]
  }
  const currentYearResult = (data.current_year || []).map((item) => {
    return { x: MONTHS[item.date.split('-')[1]], y: item.amount }
  })
  const previousYearResult = (data.previous_year || []).map((item) => {
    return { x: MONTHS[item.date.split('-')[1]], y: item.amount }
  })
  const chartData = {
    labels: labels,
    datasets: [
      {
        currency: currency,
        label: currentYear,
        data: currentYearResult,
        backgroundColor: "#4c51bf",
        borderColor: "#4c51bf",
        radius: 4,
        hoverRadius: 7,
        tension: 0.4,
      },
      {
        currency: currency,
        label: currentYear - 1,
        data: previousYearResult,
        backgroundColor: "#fff",
        borderColor: "#fff",
        radius: 4,
        hoverRadius: 7,
        tension: 0.4,
      },
    ],
  }

  return (
    <CardLineChart options={chartOptions} data={chartData} />
  )
}

export default ResultChartComponent
