import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  fetchFinancialYears,
  updateActiveFinancialYear,
} from './financialYearsAPI'
import auth from '../../auth/auth'

export const initialFinancialYearsState = {
  status: 'idle',
  data: [],
  error: null,
}

export const fetchFinancialYearsAsync = createAsyncThunk(
  'financialYears/fetchFinancialYears',
  async () => {
    const response = await fetchFinancialYears()
    return response.data
  }
)

export const updateActiveFinancialYearAsync = createAsyncThunk(
  'financialYears/updateActiveFinancialYear',
  async (financialYearId) => {
    const response = await updateActiveFinancialYear(financialYearId)
    auth.setActiveFinancialYear(response.data.id)
    return response.data.id
  }
)

export const financialYearsSlice = createSlice({
  name: 'financialYears',
  initialState: initialFinancialYearsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialYearsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFinancialYearsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchFinancialYearsAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateActiveFinancialYearAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateActiveFinancialYearAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.activeFinancialYearId = action.payload
      })
      .addCase(updateActiveFinancialYearAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})


export const selectFinancialYearsData = (state) => state.financialYears.data;
export const selectFinancialYearsStatus = (state) => state.financialYears.status;
export const selectFinancialYearsError = (state) => state.financialYears.error;

export default financialYearsSlice.reducer;
