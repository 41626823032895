import request from '../../services/request'

export const fetchFinancialYears = () => {
  return request('get', 'financial_years')
}

export const updateActiveFinancialYear = (financialYearId) => {
  return request('put', 'employees/update_active_financial_year', {
    financial_year_id: financialYearId
  })
}
