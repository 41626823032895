import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'

import HeaderStats from "../../components/Headers/HeaderStats.js"
import CardLatestTransactions from "../../components/Cards/CardLatestTransactions"
import BankAuthenticationComponent from './components/BankAuthenticationComponent'
import KycComponent from './components/KycComponent'
import ResultChartComponent from './components/ResultChartComponent'
import ComparisonChartComponent from './components/ComparisonChartComponent'
import HighestExpenditureChartComponent from './components/HighestExpenditureChartComponent'
import {
  selectBankAccountsData,
  selectBankAccountsStatus,
  fetchBankAccountsAsync,
  selectEconomyData,
  selectEconomyStatus,
  fetchEconomyChartAsync,
  selectTransactions,
  selectTransactionsStatus,
  fetchTransactionsAsync,
  selectKeyFiguresSummaryData,
  selectKeyFiguresSummaryStatus,
  fetchKeyFiguresSummaryAsync,
} from './dashboardSlice'

export default function Dashboard() {
  const dispatch = useDispatch()

  const bankAccountsData = useSelector(selectBankAccountsData)
  const bankAccountsStatus = useSelector(selectBankAccountsStatus)
  const economyData = useSelector(selectEconomyData)
  const economyStatus = useSelector(selectEconomyStatus)
  const transactions = useSelector(selectTransactions)
  const transactionsStatus = useSelector(selectTransactionsStatus)
  const keyFiguresSummaryData = useSelector(selectKeyFiguresSummaryData)
  const keyFiguresSummaryStatus = useSelector(selectKeyFiguresSummaryStatus)
  const [filterOnMissingAttachment, setFilterOnMissingAttachment] = useState(false)
  const [bankAccountOptions, setBankAccountOptions] = useState([])
  const [accountNumber, setAccountNumber] = useState({})
  const [transactionPage, setTransactionPage] = useState(1)

  useEffect(() => {
    if (bankAccountsStatus === 'idle') {
      dispatch(fetchBankAccountsAsync())
    }
  }, [bankAccountsStatus, dispatch])

  useEffect(() => {
    if (bankAccountsStatus === 'succeeded') {
      const accounts = bankAccountsData.map((account) => {
        return {
          label: account.description,
          value: account.number,
        }
      }).sort((x, y) => {
        return x.value === 1930 ? -1 : y.value === 1930 ? 1 : 0;
      })
      setBankAccountOptions(accounts)
      const defaultAccount = bankAccountsData.find(acc => acc.number === 1930)
      if (defaultAccount) {
        setAccountNumber({
          label: defaultAccount.description,
          value: defaultAccount.number
        })
      }
    }
  }, [bankAccountsData, bankAccountsStatus, dispatch])

  useEffect(() => {
    if (economyStatus === 'idle') {
      dispatch(fetchEconomyChartAsync())
    }
  }, [economyStatus, dispatch])

  useEffect(() => {
    if (transactionsStatus === 'idle' && accountNumber.value !== undefined) {
      dispatch(fetchTransactionsAsync({
        accountNumber: accountNumber.value,
        page: 1,
        missingAttachment: false
      }))
    }
  }, [transactionsStatus, accountNumber, bankAccountsStatus, dispatch])

  useEffect(() => {
    if (keyFiguresSummaryStatus === 'idle') {
      dispatch(fetchKeyFiguresSummaryAsync())
    }
  }, [keyFiguresSummaryStatus, dispatch])

  const fetchTransactions = async(accountNumber, page) => {
    setAccountNumber(accountNumber)
    setTransactionPage(page)
    dispatch(fetchTransactionsAsync({
      accountNumber: accountNumber.value,
      page: page,
      missingAttachment: false
    }))
  }

  const toggleMissingAttachmentFilter = async(missingAttachment) => {
    setFilterOnMissingAttachment(missingAttachment)

    dispatch(fetchTransactionsAsync({
      accountNumber: accountNumber.value,
      page: transactionPage,
      missingAttachment: missingAttachment ? true : false
    }))
  }

  return (
    <>
      <HeaderStats data={keyFiguresSummaryData}/>
      <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 mb-12 xl:mb-0 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-slate-50 w-full shadow-lg rounded-t">
              <div className="block w-full text-left p-4">
                <FormControl style={{width: '50%'}}>
                  <Select
                    value={accountNumber}
                    options={bankAccountOptions}
                    isSearchable={false}
                    onChange={(value) => fetchTransactions(value, 1)}
                    styles={{
                      control: provided => ({
                        ...provided,
                        cursor: 'pointer'
                      }),
                      menu: provided => ({
                        ...provided,
                        width: '350px',
                      }),
                      option: provided => ({
                        ...provided,
                        cursor: 'pointer'
                      }),
                    }}
                  />
                </FormControl>
                {accountNumber.value !== 1630 &&
                  <>
                    <Checkbox onClick={() => toggleMissingAttachmentFilter(!filterOnMissingAttachment)}/>
                    Visa endast saknade underlag
                  </>
                }
              </div>
            </div>
            <CardLatestTransactions
              transactions={transactions}
              loading={transactionsStatus === 'loading'}
              fetchTransactions={fetchTransactions}
              transactionType={accountNumber}
              transactionPage={transactionPage}
            />
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <ComparisonChartComponent
              data={{
                expenditure: economyData.expenditure,
                income: economyData.income,
              }}
            />
            <ResultChartComponent data={economyData.result} />
            <HighestExpenditureChartComponent
              expenditures={keyFiguresSummaryData.highest_expenditures}
            />
          </div>
        </div>
      </div>
      <BankAuthenticationComponent />
      <KycComponent />
    </>
  )
}
