import request from '../../services/request'

export const fetchCompanies = () => {
  return request('get', 'employees/companies')
}

export const updateActiveCompany = (companyId) => {
  return request('put', 'employees/update_active_company', {
    company_id: companyId
  })
}
