import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  fetchCompanies,
  updateActiveCompany,
} from './companiesAPI'
import auth from '../../auth/auth'

export const initialCompaniesState = {
  status: 'idle',
  data: [],
  error: null,
}

export const fetchCompaniesAsync = createAsyncThunk(
  'companies/fetchCompanies',
  async () => {
    const response = await fetchCompanies()
    return response.data
  }
)

export const updateActiveCompanyAsync = createAsyncThunk(
  'companies/updateActiveCompany',
  async (activeCompanyId) => {
    const response = await updateActiveCompany(activeCompanyId)
    auth.setActiveCompany(response.data.id)
    auth.setActiveFinancialYear(response.data.latest_financial_year.id)
    return response.data.id
  }
)

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialCompaniesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCompaniesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchCompaniesAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateActiveCompanyAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateActiveCompanyAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.activeCompanyId = action.payload
      })
      .addCase(updateActiveCompanyAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})


export const selectCompaniesData = (state) => state.companies.data;
export const selectCompaniesStatus = (state) => state.companies.status;
export const selectCompaniesError = (state) => state.companies.error;

export default companiesSlice.reducer;
