import React, { useState, useEffect } from 'react'
import Decimal from 'decimal.js';
import Select from "react-select";
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTextField from '../../../components/StyledTextField'
import SaveIcon from '@mui/icons-material/Save';
import i18n from '../../../i18n'
import { vatToAccount } from '../../../utils/accounts'
import {
  selectArticleStyles,
  selectUnitStyles,
  selectDiscountStyles,
  selectVatStyles,
  selectAccountStyles,
} from './SelectStyles';

export default function InvoiceRow(props) {
  const t = i18n.getFixedT()
  const {
    index,
    row,
    accountOptions,
    searchAccounts,
    articleOptions,
    removeInvoiceRow,
    updateInvoiceRow,
    vatOptions,
    unitOptions,
    discountOptions,
    selectArticle,
    createArticle,
    errors,
  } = props;

  const [canAddArticle, setCanAddArticle] = useState(false)

  useEffect(() => {
    if (row.article.value) {
      return
    }
    if (row.description && row.amount && row.unit.value && row.vat.value && row.account.value) {
      setCanAddArticle(true)
    } else {
      setCanAddArticle(false)
    }
  }, [row, canAddArticle])

  const onUpdate = (field, value) => {
    const updatedRow = row
    updatedRow[field] = value

    if (field === "vat") {
      const accountNumber = vatToAccount(new Decimal(value.value))
      updatedRow.account = {
        label: accountNumber,
        value: accountNumber,
      }
    }

    updateInvoiceRow(updatedRow, index)
  }

  const onAddArticle = async(e) => {
    e.preventDefault()

    const params = {
      description: row.description,
      sales_price: row.amount,
      vat: row.vat.value,
      unit_id: row.unit.value,
      sales_account: row.account.value
    }

    const response = await createArticle(params)
    const article = {label: response.description, value: response.id}
    onUpdate('article', article)
  }

  const onSelectArticle = (value) => {
    const updatedRow = row
    updatedRow.article = value

    selectArticle(value, updatedRow, index)
  }

  const onRemoveInvoiceRow = (e) => {
    e.preventDefault()
    removeInvoiceRow(index)
  }

  return (
    <Grid container justify="flex-start" spacing={1}>
      <Grid item xs={12} sm={12} md={2} lg={1.25}>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder=""
            value={row.article}
            options={articleOptions}
            onChange={(value) => onSelectArticle(value)}
            styles={selectArticleStyles}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <FormControl fullWidth>
          <StyledTextField
            id="description"
            value={row.description}
            onChange={(e) => onUpdate('description', e.target.value)}
            size='small'
            fontSize='13px'
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder=""
            value={row.unit}
            options={unitOptions}
            isSearchable={false}
            onChange={(value) => onUpdate('unit', value)}
            styles={selectUnitStyles(errors.includes('unit_id'))} // TODO: Change false to validation error
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth>
          <StyledTextField
            type='number'
            value={row.quantity}
            onChange={(e) => onUpdate('quantity', e.target.value)}
            size='small'
            fontSize='13px'
            error={errors.includes('quantity')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth>
          <StyledTextField
            type='number'
            value={row.amount}
            onChange={(e) => onUpdate('amount', e.target.value)}
            size='small'
            fontSize='13px'
            error={errors.includes('amount')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            options={vatOptions}
            value={row.vat}
            isSearchable={false}
            onChange={(value) => onUpdate('vat', value)}
            styles={selectVatStyles(errors.includes('vat'))} // TODO: Change false to validation error
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder=""
            options={discountOptions}
            value={row.discount}
            isSearchable={false}
            onChange={(value) => onUpdate('discount', value)}
            styles={selectDiscountStyles(false)} // TODO: Change false to validation error
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={1.1}>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder={t('account')}
            options={accountOptions}
            value={row.account}
            getOptionLabel={option =>`${option.value} - ${option.label}`}
            onKeyDown={(e) => searchAccounts(e.target.value)}
            onChange={(value) => onUpdate('account', value)}
            styles={selectAccountStyles} // TODO: Change false to validation error
          />
        </FormControl>
      </Grid>
      {index !== 0 && (
        <Grid item xs={12} sm={12} md={4} lg={0.4}>
          <FormControl fullWidth>
            <DeleteIcon
              className="text-red-500"
              style={{cursor: 'pointer', marginTop: '2px', fontSize: '36px'}}
              onClick={(e) => onRemoveInvoiceRow(e)}
            />
          </FormControl>
        </Grid>
      )}
      {!row.article.value && row.description && row.amount && row.unit.value && (
        <Grid item xs={12} sm={12} md={4} lg={0.4}>
          <FormControl fullWidth>
            <SaveIcon
              className="text-emerald-500"
              style={{cursor: 'pointer', marginTop: '2px', fontSize: '36px'}}
              onClick={(e) => onAddArticle(e)}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>

  )
}
