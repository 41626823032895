import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import FileUploader from '../../components/FileUploader'
import PaymentsList from './components/paymentsList'
import Filter from './components/filter'
import BankAuthenticationComponent from './components/bankAuthenticationComponent'
import {
  paymentsSlice,
  approvePaymentsAsync,
  fetchPaymentsAsync,
  createPaymentFromAttachmentAsync,
  selectPaymentsData,
  selectPaymentsStatus,
  cancelPaymentAsync,
} from './paymentsSlice'

export default function Payments(props) {
  const dispatch = useDispatch()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [paymentsToApprove, setPaymentsToApprove] = useState([])
  const [bankAuthModalOpen, setBankAuthModalOpen] = useState(false)
  const [basketId, setBasketId] = useState(null)
  const [statuses, setStatuses] = useState(['waiting_for_approval', 'authorization_pending'])

  const paymentsData = useSelector(selectPaymentsData)
  const paymentsStatus = useSelector(selectPaymentsStatus)

  useEffect(() => {
    if (paymentsStatus === 'idle') {
      dispatch(fetchPaymentsAsync({page: 1, statuses: statuses}))
    }
  }, [paymentsStatus, statuses, dispatch])

  const handlePageClick = (_, page) => {
    fetchPayments(page)
  };

  const handlePayAll = (add) => {
    const paymentIds = []
    if (add) {
      paymentsData.records.map((p) => {
        if (p.status === 'waiting_for_approval') {
          paymentIds.push(p.id)
        }
      })
      setPaymentsToApprove(paymentIds)
    } else {
      setPaymentsToApprove([])
    }
  }

  const handleAddPayment = (paymentId, add) => {
    const currPayments = [...paymentsToApprove]
    if (add) {
      currPayments.push(paymentId)
    } else {
      const index = currPayments.findIndex(id => id === paymentId);
      if (index > -1) {
        currPayments.splice(index, 1)
      }
    }

    setPaymentsToApprove(currPayments)
  }

  const handleAuthError = () => {
    setPaymentsToApprove([])
    dispatch(fetchPaymentsAsync({page: 1, statuses: statuses}))
  }

  const confirmPayments = (payments) => {
    setPaymentsToApprove([])
    dispatch(paymentsSlice.actions.confirmPayments(payments))
  }

  const fetchPayments = (page) => {
    dispatch(fetchPaymentsAsync({page: page, statuses: statuses}))
  }

  const cancelPayment = (paymentId) => {
    dispatch(cancelPaymentAsync(paymentId))
  }

  const onUpload = async(attachments, fileName) => {
    const formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('files[]', attachment.file);
    });

    const response = await dispatch(createPaymentFromAttachmentAsync(formData))

    if (response.type === 'payments/createPaymentFromAttachmentAsync/fulfilled') {
      setUploadModalOpen(false)
      setStatuses(['unprocessed', 'supplier_invoice_created'])
      dispatch(fetchPaymentsAsync({page: 1, statuses: ['unprocessed', 'supplier_invoice_created']}))
    }
  }

  const filterOnStatus = (statuses) => {
    setStatuses(statuses)
    dispatch(fetchPaymentsAsync({page: 1, statuses: statuses}))
  }

  return (
    <div className="relative md:pt-32 pb-32 pt-12">
      <Grid container justify="center">
        <Grid item xs={11} xsOffset={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FormControl fullWidth>
                <button
                  onClick={() => setUploadModalOpen(true)}
                  className="bg-emerald-500 text-white py-4 px-4 cursor-pointer"
                >
                  Ladda upp för betalning
                </button>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <button
                disabled={paymentsToApprove.length === 0}
                onClick={() => setBankAuthModalOpen(true)}
                className={
                        "text-white py-4 px-4 cursor-pointer " +
                        (paymentsToApprove.length === 0
                          ? "bg-slate-200"
                          : "bg-emerald-500")
                      }
              >
                Godkänn betalningar
              </button>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{marginTop: '10px'}}>
            <Filter
              statuses={statuses}
              filterOnStatus={filterOnStatus}
            />
          </Grid>
        </Grid>

        <Grid item xs={11} xsOffset={0.5} sx={{marginTop: '8px'}}>
          <PaymentsList
            fetchPayments={fetchPayments}
            payments={paymentsData}
            paymentsToApprove={paymentsToApprove}
            handleAddPayment={handleAddPayment}
            handlePayAll={handlePayAll}
            cancelPayment={cancelPayment}
          />
        </Grid>
      </Grid>

      <FileUploader
        title="Ladda upp underlag för betalning"
        onClose={() => setUploadModalOpen(false)}
        onUpload={onUpload}
        open={uploadModalOpen}
        multiple={true}
      />
      <BankAuthenticationComponent
        open={bankAuthModalOpen}
        onError={handleAuthError}
        onSuccess={confirmPayments}
        onClose={() => setBankAuthModalOpen(false)}
        paymentsToApprove={paymentsData.records?.filter(p => paymentsToApprove.some(id => id === p.id))}
      />
    </div>
  )
}
