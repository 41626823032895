import React from 'react';
import TextField from '@mui/material/TextField'

export default function StyledTextField(props) {
  const {
    id,
    field,
    placeholder,
    size,
    onChange,
    type,
    value,
    fontSize,
    height,
    error,
  } = props;

  return (
    <TextField
      id={id}
      {...field}
      placeholder={placeholder}
      size={size}
      onChange={onChange}
      value={value || ''}
      type={type || "text"}
      sx={{
        borderRadius: '4px',
        border: error ? '1px solid red' : 'none',
        background: '#fff',
        '& .MuiInputBase-input': {
          fontSize: fontSize || '14px',
          height: height || '23px',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          },
          '&.Mui-active fieldset': {
            border: '1px solid #0000006b',
            borderColor: '#0000006b'
          }
        }
      }}
    />
  )
}
