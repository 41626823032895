import { authenticate } from '../features/login/loginAPI'

class Auth {
  authenticate(email, password, rememberEmail) {
    return authenticate(email, password)
      .then(data => {
        this.setToken(data.access_token)
        this.setTokenExpiresAt(data.expires_at)
        this.setRememberedEmail(rememberEmail)
        this.setActiveCompany(data.active_company_id)
        this.setActiveFinancialYear(data.active_financial_year_id)
        if (rememberEmail) {
          this.setEmail(email)
        }

        this.onAuthenticated && this.onAuthenticated(data)
        return data
      }).catch((err) => {
        console.log("err: ", err)
        // TODO: Handle error?
    })
  }

  logout() {
    this.removeSessionData()
  }

  isAuthenticated() {
    const token = this.getToken()

    if (token === undefined || token === null) {
      return false
    }

    // Check if the token has expired here...

    return true
  }

  getToken() {
    return localStorage.getItem('EM_SESSION_TOKEN')
  }

  setToken(token) {
    localStorage.setItem('EM_SESSION_TOKEN', token)
  }

  getTokenExpiresAt() {
    return localStorage.getItem('EM_SESSION_TOKEN_EXPIRES_AT')
  }

  setTokenExpiresAt(expiresAt) {
    localStorage.setItem('EM_SESSION_TOKEN_EXPIRES_AT', expiresAt)
  }

  getEmail() {
    return localStorage.getItem('EM_SESSION_EMAIL')
  }

  setEmail(email) {
    localStorage.setItem('EM_SESSION_EMAIL', email)
  }

  getRememberedEmail() {
    return this.typecast(localStorage.getItem('EM_EMAIL_REMEMBERED'))
  }

  setRememberedEmail(rememberEmail) {
    if (rememberEmail) {
      localStorage.setItem('EM_EMAIL_REMEMBERED', rememberEmail)
    } else {
      localStorage.removeItem('EM_EMAIL_REMEMBERED')
    }
  }

  getActiveFinancialYear() {
    const activeFinancialYearId = localStorage.getItem('EM_SESSION_ACTIVE_FINANCIAL_YEAR')

    if (activeFinancialYearId) {
      return parseInt(activeFinancialYearId)
    }

    return activeFinancialYearId
  }

  setActiveFinancialYear(financialYearId) {
    localStorage.setItem('EM_SESSION_ACTIVE_FINANCIAL_YEAR', financialYearId)
  }

  getActiveCompany() {
    const activeCompanyId = localStorage.getItem('EM_SESSION_ACTIVE_COMPANY')

    if (activeCompanyId) {
      return parseInt(activeCompanyId)
    }

    return activeCompanyId
  }

  setActiveCompany(activeCompanyId) {
    localStorage.setItem('EM_SESSION_ACTIVE_COMPANY', activeCompanyId)
  }

  removeSessionData() {
    localStorage.removeItem('EM_SESSION_TOKEN')
    localStorage.removeItem('EM_SESSION_TOKEN_EXPIRES_AT')
    localStorage.removeItem('EM_SESSION_ACTIVE_COMPANY')
    localStorage.removeItem('EM_SESSION_ACTIVE_FINANCIAL_YEAR')
  }

  typecast(value) {
    switch (value) {
      case 'true':
        value = true
        break
      case 'false':
        value = false
        break
      default:
        value = false
    }

    return value
  }
}

const auth = new Auth()
export default auth
