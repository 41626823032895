import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Pagination from '@mui/material/Pagination'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { translateStatus } from '../utils'
import { formatMoneyWithCurrency } from '../../../utils/money'

export default function PaymentsList(props) {
  const {
    fetchPayments,
    payments = {},
    paymentsToApprove,
    handlePayAll,
    handleAddPayment,
    cancelPayment,
  } = props

  const [payablePayments, setPayablePayments] = useState([])

  useEffect(() => {
    const payable = []
    payments.records?.map((p) => {
      if (p.status === 'waiting_for_approval' || p.status === 'authorization_pending') {
        payable.push(p.id)
      }
    })
    setPayablePayments(payable)
  }, [payments])

  const handlePageClick = (_, page) => {
    fetchPayments(page)
  };

  const handleCancelPayment = (paymentId) => {
    if (window.confirm("Är du säker på att du vill avbryta betalningen?")) {
      cancelPayment(paymentId)
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer className="bg-white">
          <Table className="invoices-table">
            <TableHead style={{background: '#0284c7'}}>
              <TableRow>
                <TableCell sx={{color: '#FFFFFF'}}>
                  <FormControlLabel
                    sx={{margin: 0}}
                    control={
                      <Checkbox
                        sx={{
                          color: '#FFFFFF',
                          '&.Mui-checked': {
                            color: '#FFFFFF',
                          },
                        }}
                        disabled={payablePayments.length === 0}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={payablePayments.length > 0 && payablePayments.length === paymentsToApprove.length}
                        onChange={(e) => handlePayAll(e.target.checked)}
                      />
                    }
                  />
                </TableCell>
                <TableCell sx={{color: '#FFFFFF'}}><b>Löpnummer</b></TableCell>
                <TableCell sx={{color: '#FFFFFF'}}>
                  {payments.records?.some(p => p.supplier_name) &&
                    <b>Leverantör</b>
                  }
                </TableCell>
                <TableCell sx={{color: '#FFFFFF'}}>
                  {payments.records?.some(p => p.invoice_number) &&
                    <b>Fakturanummer</b>
                  }
                </TableCell>
                <TableCell sx={{color: '#FFFFFF'}}>
                  {payments.records?.some(p => p.amount > 0) &&
                    <b>Summa</b>
                  }
                </TableCell>
                <TableCell sx={{color: '#FFFFFF'}}>
                  {payments.records?.some(p => p.due_date) &&
                    <b>Förfallodatum</b>
                  }
                </TableCell>
                <TableCell sx={{color: '#FFFFFF'}}><b>Status</b></TableCell>
                <TableCell sx={{color: '#FFFFFF'}}><b>Faktura</b></TableCell>
                <TableCell sx={{color: '#FFFFFF'}}><b>Avbryt</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.records?.map((payment) => (
                <TableRow key={payment.id}>
                  <TableCell sx={{color: '#FFFFFF'}}>
                    <FormControlLabel
                      sx={{margin: 0}}
                      control={
                        <Checkbox
                          disabled={!["waiting_for_approval", "authorization_pending"].includes(payment.status)}
                          sx={{
                            color: '#000000',
                            '&.Mui-checked': {
                              color: '#000000',
                            },
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                          checked={paymentsToApprove.some(id => id === payment.id)}
                          onChange={(e) => handleAddPayment(payment.id, e.target.checked)}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>{payment.payment_number}</TableCell>
                  <TableCell>
                    {payment.supplier_name &&
                      payment.supplier_name
                    }
                  </TableCell>
                  <TableCell>
                    {payment.invoice_number &&
                      payment.invoice_number
                    }
                  </TableCell>
                  <TableCell>
                    {payment.amount > 0 &&
                      formatMoneyWithCurrency(payment.amount, 'SEK', false)
                    }
                  </TableCell>
                  <TableCell>
                    {payment.due_date &&
                      payment.due_date
                    }
                  </TableCell>
                  <TableCell>{translateStatus(payment.status)}</TableCell>
                  <TableCell>
                    {(payment.attachments || []).map((attachment, i) => (
                      <Button
                        key={i}
                        variant="outlined"
                        size="small"
                        onClick={() => window.open(attachment.url)}
                      >
                        Öppna faktura
                      </Button>
                    ))}
                  </TableCell>
                  {payment.cancellable &&
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleCancelPayment(payment.id)}
                      >
                        Avbryt
                      </Button>
                    </TableCell>
                  }
                </TableRow>
              ))}
              {payments?.meta?.total_pages > 0 &&
                <TableRow>
                  <TableCell>
                    <Pagination
                      count={payments.meta.total_pages || 1}
                      onChange={handlePageClick}
                      variant='outlined'
                      shape='rounded'
                    />
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
