import React from "react";

import FinancialYears from '../../features/financialYears/FinancialYears'
import Companies from '../../features/companies/Companies'
import Logout from '../../features/logout/Logout'

export default function Navbar() {
  return (
    <nav className="absolute top-0 left-0 w-full z-10 bg-sky-600 md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
      <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <div className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
        >
          <Companies />
        </div>
        <div className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3 p-1">
          <FinancialYears />
          <Logout/>
        </div>
      </div>
    </nav>
  );
}
