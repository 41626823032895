import auth from '../../auth/auth'

const activeFinancialYearIdReducer = (state = null, action) => {
  switch (action.type) {
    case 'companies/updateActiveCompany/fulfilled':
      return null;
    case 'financialYears/fetchFinancialYears/fulfilled':
      return auth.getActiveFinancialYear();
    case 'financialYears/updateActiveFinancialYear/fulfilled':
      return action.payload;
    default:
      return state;
  }
}

export const selectActiveFinancialYear = (state) => {
  return state.financialYears.data.find((financialYear) => {
    return financialYear.id === state.activeFinancialYearId;
  });
};

export default activeFinancialYearIdReducer;
