export const selectVatStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      width: '100%',
      cursor: 'pointer',
      border: error ? '1px solid red' : '1px solid #0000003b',
      height: '40px',
      marginTop: '-1px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid #0000006b',
        boxShadow: 'none'
      }
    }),
    dropdownIndicator: (provided) => ({
    ...provided,
      width: '25px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
  }
}

export const selectArticleStyles = {
  menu: (provided, state) => ({
    ...provided,
    minWidth: 400,
    width: '100%',
    cursor: 'pointer',
    zIndex: 999,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    minWidth: 400,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    boxShadow: 'none',
    border: '1px solid #0000003b',
    width: '100%',
    cursor: 'pointer',
    height: '40px',
    marginTop: '-1px',
    '&:hover': {
      border: '1px solid #0000006b',
      boxShadow: 'none'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    width: '25px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
}

export const selectCustomerStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 0,
      minWidth: '300px',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
      height: '35px',
      padding: '5px 0 0 5px',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      boxShadow: 'none',
      border: error ? '1px solid red' : '1px solid #0000003b',
      cursor: 'pointer',
      textAlign: 'left',
      height: '48px',
      marginTop: '-1px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid #0000006b',
        boxShadow: 'none'
      }
    })
  }
}

export const selectUnitStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      boxShadow: 'none',
      width: '100%',
      cursor: 'pointer',
      border: error ? '1px solid red' : '1px solid #0000003b',
      height: '40px',
      marginTop: '-1px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid #0000006b',
        boxShadow: 'none'
      }
    }),
    dropdownIndicator: (provided) => ({
    ...provided,
      width: '25px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
  }
}

export const selectDiscountStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 4,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      boxShadow: 'none',
      width: '100%',
      cursor: 'pointer',
      marginTop: 0,
      marginLeft: 4,
      height: '40px',
      border: error ? '1px solid red' : '1px solid #0000003b',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid #0000006b',
        boxShadow: 'none'
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      width: '25px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
  }
}

export const selectTermsOfPaymentsStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 0,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
      height: '35px',
      padding: '5px 0 0 5px',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      boxShadow: 'none',
      border: '1px solid #0000003b',
      cursor: 'pointer',
      textAlign: 'left',
      height: '48px',
      marginTop: '-1px',
      '&:hover': {
        border: '1px solid #0000006b',
        boxShadow: 'none'
      }
    })
  }
}

export const selectAccountStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      minWidth: 400,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      position: "absolute",
      right: '5px',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
      minWidth: 400,
      width: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      boxShadow: 'none',
      border: '1px solid #0000003b',
      width: '100%',
      cursor: 'pointer',
      height: '40px',
      marginLeft: '5px',
      '&:hover': {
        border: '1px solid #0000006b',
        boxShadow: 'none'
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      width: '25px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
  }
}
