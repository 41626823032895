import {environment} from "../config/environment";
import auth from '../auth/auth';

const fileUpload = (path, payload) => {
  return fetch(`${environment().apiURL}/${path}`, {
    method: 'POST',
    body: payload,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${auth.getToken()}`,
      'X-Company-Id': auth.getActiveCompany(),
      'X-Financial-Year-Id': auth.getActiveFinancialYear()
    },
  })
}

export default fileUpload
