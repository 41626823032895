import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import StyledTextField from '../../../components/StyledTextField'
import ClearIcon from '@mui/icons-material/Clear'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  height: '72vh',
  overflowX: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  boxShadow: 24,
  p: 4,
};

const requiredFields = ['name']

export default function CustomerForm(props) {
  const { open, close, onSave, customer } = props;

  const [errors, setErrors] = useState([])
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [corporateIdentityNumber, setCorporateIdentityNumber] = useState('')

  useEffect(() => {
    if (customer.id) {
      setId(customer.id)
      setName(customer.name)
      setAddress(customer.address)
      setCity(customer.city)
      setEmail(customer.email)
      setPhoneNumber(customer.phone)
      setZipCode(customer.zip_code)
      setIsPrivate(customer.private)
      setCorporateIdentityNumber(customer.corporate_identity_number)
    } else {
      setId('')
      setName('')
      setAddress('')
      setCity('')
      setEmail('')
      setPhoneNumber('')
      setZipCode('')
      setIsPrivate(false)
      setCorporateIdentityNumber('')
    }
  }, [customer])

  const onSubmit = () => {
    const params = {
      name: name,
      address: address,
      city: city,
      email: email,
      phone: phoneNumber,
      private: isPrivate,
      zip_code: zipCode,
      corporate_identity_number: corporateIdentityNumber
    }

    const errors = []
    Object.entries(params).forEach(([key, value]) => {
      if (requiredFields.includes(key) && value === '') {
        errors.push(key)
      }
    })

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    if (id) {
      params.id = id
    }

    onSave(params)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{backgroundColor: 'none'}}
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} lg={10}>
            <h1 style={{fontSize: '20px'}}>{id ? 'Redigera kund' : 'Skapa kund'}</h1>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <ClearIcon
              onClick={close}
              sx={{
                float: 'right',
                margin: '-20px -20px 0',
                cursor: 'pointer'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Namn"
                error={errors.includes('name')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="corporateIdentityNumber"
                value={corporateIdentityNumber}
                onChange={(e) => setCorporateIdentityNumber(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Orgnummer/Personnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="E-postadress"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Telefonnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Adress"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Postnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Ort"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
              label="Privatkund"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '13px'
                },
              }}
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={() => setIsPrivate(!isPrivate)}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <button
              onClick={onSubmit}
              className="bg-emerald-500 text-white py-4 px-8 cursor-pointer"
            >
              Spara
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
