import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  fetchActiveCompany,
  searchAccounts,
  searchCustomers,
  fetchArticles,
  fetchTermsOfPayment,
  fetchUnits,
  createInvoice,
  updateInvoice,
  fetchInvoices,
  sendInvoice,
  createArticle,
  createCustomer,
  updateCustomer,
} from './invoicesAPI'

export const initialInvoicesState = {
  activeCompany: {
    data: {},
    status: 'idle',
    errors: null,
  },
  customers: {
    data: [],
    status: 'idle',
    errors: null,
  },
  accounts: {
    data: [],
    status: 'idle',
    errors: null,
  },
  articles: {
    data: [],
    status: 'idle',
    errors: null,
  },
  units: {
    data: [],
    status: 'idle',
    errors: null,
  },
  termsOfPayments: {
    data: [],
    status: 'idle',
    errors: null,
  },
  invoices: {
    status: 'idle',
    error: null,
    data: {
      records: [],
      meta: {}
    }
  }
}

export const fetchActiveCompanyAsync = createAsyncThunk(
  'invoices/fetchActiveCompany',
  async () => {
    const response = await fetchActiveCompany()
    return response.data
  }
)

export const searchCustomersAsync = createAsyncThunk(
  'invoices/searchCustomers',
  async (name) => {
    const response = await searchCustomers(name)
    return response.data
  }
)

export const searchAccountsAsync = createAsyncThunk(
  'invoices/searchAccounts',
  async (query) => {
    const response = await searchAccounts(query)
    return response.data
  }
)

export const fetchArticlesAsync = createAsyncThunk(
  'invoices/fetchArticles',
  async () => {
    const response = await fetchArticles()
    return response.data
  }
)

export const fetchTermsOfPaymentsAsync = createAsyncThunk(
  'invoices/fetchTermsOfPayment',
  async () => {
    const response = await fetchTermsOfPayment()
    return response.data
  }
)

export const fetchUnitsAsync = createAsyncThunk(
  'invoices/fetchUnits',
  async () => {
    const response = await fetchUnits()
    return response.data
  }
)

export const createInvoiceAsync = createAsyncThunk(
  'invoices/createInvoice',
  async (params) => {
    const response = await createInvoice(params)
    return response.data
  }
)

export const updateInvoiceAsync = createAsyncThunk(
  'invoices/updateInvoice',
  async (params) => {
    const response = await updateInvoice(params)
    return response.data
  }
)

export const fetchInvoicesAsync = createAsyncThunk(
  'invoices/fetchInvoices',
  async (page) => {
    const response = await fetchInvoices(page)
    return response.data
  }
)

export const sendInvoiceAsync = createAsyncThunk(
  'invoices/sendInvoice',
  async (invoiceId) => {
    const response = await sendInvoice(invoiceId)
    return response.data
  }
)

export const createArticleAsync = createAsyncThunk(
  'invoices/createArticle',
  async (params) => {
    const response = await createArticle(params)
    return response.data
  }
)

export const createCustomerAsync = createAsyncThunk(
  'invoices/createCustomer',
  async (params) => {
    const response = await createCustomer(params)
    return response.data
  }
)

export const updateCustomerAsync = createAsyncThunk(
  'invoices/updateCustomer',
  async (params) => {
    const response = await updateCustomer(params)
    return response.data
  }
)


export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: initialInvoicesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveCompanyAsync.pending, (state) => {
        state.activeCompany.status = 'loading'
      })
      .addCase(fetchActiveCompanyAsync.fulfilled, (state, action) => {
        state.activeCompany.status = 'succeeded'
        state.activeCompany.data = action.payload
      })
      .addCase(fetchActiveCompanyAsync.rejected, (state, action) => {
        state.activeCompany.status = 'failed'
        state.activeCompany.error = action.error.message
      })
      .addCase(searchCustomersAsync.pending, (state) => {
        state.customers.status = 'loading'
      })
      .addCase(searchCustomersAsync.fulfilled, (state, action) => {
        state.customers.status = 'succeeded'
        state.customers.data = action.payload
      })
      .addCase(searchCustomersAsync.rejected, (state, action) => {
        state.customers.status = 'failed'
        state.customers.error = action.error.message
      })
      .addCase(searchAccountsAsync.pending, (state) => {
        state.accounts.status = 'loading'
      })
      .addCase(searchAccountsAsync.fulfilled, (state, action) => {
        state.accounts.status = 'succeeded'
        state.accounts.data = action.payload
      })
      .addCase(searchAccountsAsync.rejected, (state, action) => {
        state.accounts.status = 'failed'
        state.accounts.error = action.error.message
      })
      .addCase(fetchArticlesAsync.pending, (state) => {
        state.articles.status = 'loading'
      })
      .addCase(fetchArticlesAsync.fulfilled, (state, action) => {
        state.articles.status = 'succeeded'
        state.articles.data = action.payload
      })
      .addCase(fetchArticlesAsync.rejected, (state, action) => {
        state.articles.status = 'failed'
        state.articles.error = action.error.message
      })
      .addCase(fetchTermsOfPaymentsAsync.pending, (state) => {
        state.termsOfPayments.status = 'loading'
      })
      .addCase(fetchTermsOfPaymentsAsync.fulfilled, (state, action) => {
        state.termsOfPayments.status = 'succeeded'
        state.termsOfPayments.data = action.payload
      })
      .addCase(fetchTermsOfPaymentsAsync.rejected, (state, action) => {
        state.termsOfPayments.status = 'failed'
        state.termsOfPayments.error = action.error.message
      })
      .addCase(fetchUnitsAsync.pending, (state) => {
        state.units.status = 'loading'
      })
      .addCase(fetchUnitsAsync.fulfilled, (state, action) => {
        state.units.status = 'succeeded'
        state.units.data = action.payload
      })
      .addCase(fetchUnitsAsync.rejected, (state, action) => {
        state.units.status = 'failed'
        state.units.error = action.error.message
      })
      // .addCase(createInvoiceAsync.pending, (state) => {
      //   state.invoices.status = 'loading'
      // })
      .addCase(createInvoiceAsync.fulfilled, (state, action) => {
        state.invoices.status = 'succeeded'
        state.invoices.data.records = [
          action.payload,
          ...state.invoices.data.records,
        ]
      })
      .addCase(createInvoiceAsync.rejected, (state, action) => {
        state.invoices.status = 'failed'
        state.invoices.error = action.error.message
      })
      // .addCase(updateInvoiceAsync.pending, (state) => {
      //   state.invoices.status = 'loading'
      // })
      .addCase(updateInvoiceAsync.fulfilled, (state, action) => {
        state.invoices.status = 'succeeded'
        const invoices = [...state.invoices.data.records]
        const index = invoices.findIndex(invoice => invoice.id === action.payload.id)
        if (index !== -1) {
          invoices[index] = action.payload
          state.invoices.data.records = invoices
        }
      })
      .addCase(updateInvoiceAsync.rejected, (state, action) => {
        state.invoices.status = 'failed'
        state.invoices.error = action.error.message
      })
      .addCase(fetchInvoicesAsync.pending, (state) => {
        state.invoices.status = 'loading'
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.invoices.status = 'succeeded'
        state.invoices.data = action.payload
      })
      .addCase(fetchInvoicesAsync.rejected, (state, action) => {
        state.invoices.status = 'failed'
        state.invoices.error = action.error.message
      })
      .addCase(sendInvoiceAsync.fulfilled, (state, action) => {
        state.invoices.status = 'succeeded'
        const invoices = [...state.invoices.data.records]
        const index = invoices.findIndex(invoice => invoice.id === action.payload.id)
        if (index !== -1) {
          invoices[index] = action.payload
          state.invoices.data.records = invoices
        }
      })
      .addCase(sendInvoiceAsync.rejected, (state, action) => {
        state.invoices.status = 'failed'
        state.invoices.error = action.error.message
      })
      .addCase(createArticleAsync.fulfilled, (state, action) => {
        state.articles.status = 'succeeded'
        state.articles.data = [
          action.payload,
          ...state.articles.data,
        ]
      })
      .addCase(createArticleAsync.rejected, (state, action) => {
        state.articles.status = 'failed'
        state.articles.error = action.error.message
      })
      .addCase(createArticleAsync.pending, (state) => {
        state.articles.status = 'loading'
      })
      .addCase(createCustomerAsync.fulfilled, (state, action) => {
        state.customers.status = 'succeeded'
        state.customers.data = [
          action.payload,
          ...state.customers.data,
        ]
      })
      .addCase(createCustomerAsync.rejected, (state, action) => {
        state.customers.status = 'failed'
        state.customers.error = action.error.message
      })
      .addCase(createCustomerAsync.pending, (state) => {
        state.customers.status = 'loading'
      })
      .addCase(updateCustomerAsync.fulfilled, (state, action) => {
        state.customers.status = 'succeeded'
        const customers = [...state.customers.data]
        const index = customers.findIndex(customer => customer.id === action.payload.id)
        if (index !== -1) {
          customers[index] = action.payload
          state.customers.data = customers
        }
      })
      .addCase(updateCustomerAsync.rejected, (state, action) => {
        state.customers.status = 'failed'
        state.customers.error = action.error.message
      })
      .addCase(updateCustomerAsync.pending, (state) => {
        state.customers.status = 'loading'
      })
  },
})

export const selectActiveCompanyData = (state) => state.invoices.activeCompany.data;
export const selectActiveCompanyStatus = (state) => state.invoices.activeCompany.status;

export const selectCustomersData = (state) => state.invoices.customers.data;
export const selectCustomersStatus = (state) => state.invoices.customers.status;
export const selectCustomersError = (state) => state.invoices.customers.error;

export const selectAccountsData = (state) => state.invoices.accounts.data;
export const selectAccountsStatus = (state) => state.invoices.accounts.status;
export const selectAccountsError = (state) => state.invoices.accounts.error;

export const selectArticlesData = (state) => state.invoices.articles.data;
export const selectArticlesStatus = (state) => state.invoices.articles.status;
export const selectArticlesError = (state) => state.invoices.articles.error;

export const selectTermsOfPaymentsData = (state) => state.invoices.termsOfPayments.data;
export const selectTermsOfPaymentsStatus = (state) => state.invoices.termsOfPayments.status;
export const selectTermsOfPaymentsError = (state) => state.invoices.termsOfPayments.error;

export const selectUnitsData = (state) => state.invoices.units.data;
export const selectUnitsStatus = (state) => state.invoices.units.status;
export const selectUnitsError = (state) => state.invoices.units.error;

export const selectInvoicesData = (state) => state.invoices.invoices.data;
export const selectInvoicesStatus = (state) => state.invoices.invoices.status;
export const selectInvoicesError = (state) => state.invoices.invoices.error;


export default invoicesSlice.reducer;
