const requiredInvoiceFields = [
  'invoice_date',
  'due_date',
  'terms_of_payment',
  'customer_id',
]

const requiredInvoiceRowFields = [
  'unit_id',
  'quantity',
  'amount',
  'vat',
  'account_number'
]

export const validateForm = (params) => {
  const errors = []
  const rowErrors = {}

  requiredInvoiceFields.forEach((field) => {
    if (!params[field]) {
      errors.push(field)
    }
  })

  params.invoice_rows.forEach((row, i) => {
    requiredInvoiceRowFields.forEach((field) => {
      if (!row[field]) {
        if (!rowErrors[i]) {
          rowErrors[i] = [field]
        } else {
          rowErrors[i].push(field)
        }
      }
    })
  })

  return [errors, rowErrors]
}
